// In this about.js file, a functional React component called AboutPage is defined.
// The AboutPage component renders a Meta component, a HeroSection2 component, and a BioSection component
// when it is rendered on a page. The Meta component is used to set the title and description of the page for SEO purposes.
// The HeroSection2 component is used to display a banner with a title and subtitle at the top of the page.
// The BioSection component is used to display information about the company or team.

import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import BioSection from "./../components/BioSection";
import bio_hero_image from "./../images/Bio_Hero_Image.png";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage={bio_hero_image}
        bgImageOpacity={0.2}
        title="Realize your dream job with Resume Revival"
        subtitle="Transform your job search with our AI-powered resume and cover letter builder"
      />
      <BioSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="About Me"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
