import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#333",
    color: theme.palette.type === "light" ? "#000" : "#fff",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:
          theme.palette.type === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)",
      },
      "&:hover fieldset": {
        borderColor:
          theme.palette.type === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused": {
        backgroundColor: theme.palette.type === "light" ? "#fff" : "#333",
      },
    },
    "& .MuiInputBase-input": {
      color: theme.palette.type === "light" ? "#000" : "#fff",
    },
    "& .MuiInputLabel-root": {
      color:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.87)"
          : "rgba(255, 255, 255, 0.87)",
    },
    "& .MuiIconButton-root": {
      color:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.54)"
          : "rgba(255, 255, 255, 0.54)",
    },
    "& input:-webkit-autofill": {
      WebkitBoxShadow:
        theme.palette.type === "light"
          ? "0 0 0 100px #fff inset"
          : "0 0 0 100px #333 inset",
      WebkitTextFillColor: theme.palette.type === "light" ? "#000" : "#fff",
    },
  },
  paperHeader: {
    padding: "20px",
    backgroundColor: theme.palette.type === "light" ? "#e0e7ff" : "#242424",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  sectionHeaderContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    width: "100%",
    boxSizing: "border-box",
    marginBottom: 0,
  },
  sectionHeaderText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    textAlign: "center",
  },
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formContent: {
    padding: 0,
  },
  formInnerContent: {
    padding: theme.spacing(4),
  },
  formSection: {
    marginBottom: theme.spacing(4),
  },
  subsectionHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  multilineInput: {
    "& .MuiOutlinedInput-root": {
      "& textarea": {
        overflow: "hidden",
        resize: "none",
      },
    },
  },
  photoUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    position: "relative",
  },
  largeAvatar: {
    width: 200,
    height: 200,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  photoPlaceholder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
  },
  photoActions: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    gap: theme.spacing(1),
    opacity: 0,
    transition: "opacity 0.3s",
    "$photoUpload:hover &": {
      opacity: 1,
    },
  },
  editButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  photoCaption: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
  },
  profilePhotoButton: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profilePhotoIcon: {
    fontSize: theme.spacing(4),
  },
  profilePhotoAvatar: {
    width: "100%",
    height: "100%",
  },
  profilePhotoContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
}));
