// FormSection5_TargetJobInformations
import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Snackbar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  SectionHeader,
  SubsectionHeader,
  FormField,
  DateHelperText,
} from "./FormComponents";
import WorkIcon from "@material-ui/icons/Work";
import BusinessIcon from "@material-ui/icons/Business";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import GenerateApplicationMaterialsButton from "./GenerateApplicationMaterialsButton";
import GeneratedContentDisplay from "./GeneratedContentDisplay";
import ApplicationMaterialSkeleton from "./ApplicationMaterialSkeleton";

const MAX_JOB_ENTRIES = 10;
const MAX_TOOLKIT_SELECTIONS = 20;

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formContent: {
    position: "relative",
  },
  formContainer: {
    padding: theme.spacing(3),
  },
  entryContainer: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },

  formSection: {
    marginBottom: theme.spacing(3),
  },
  checkboxGrid: {
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(1),
  },
  generateButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  generatedContentContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  jobTitleHeader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(-4),
    fontWeight: 600,
  },
  materialContainer: {
    marginBottom: theme.spacing(4),
  },
}));

const materialTypeMap = {
  resume: "Resume",
  coverLetter: "Cover Letter",
  references: "References",
  recruiterMessage: "Recruiter Message",
  linkedinOptimization: "LinkedIn Profile Optimization",
  interviewPrep: "Interview Preparation",
  networkingEmails: "Networking Email Templates",
  careerDevelopmentPlan: "Career Development Plan",
  followUpEmails: "Job Application Follow-up Emails",
  salaryNegotiation: "Salary Negotiation Scripts",
  professionalBio: "Professional Bio",
  personalBranding: "Personal Branding Tips",
  skillGapAnalysis: "Skill Gap Analysis",
  companyResearch: "Company Research Reports",
};

const initialTargetJobInfo = {
  jobTitle: "",
  companyName: "",
  jobLocation: "",
  jobType: "",
  salary: "",
  applicationDeadline: "",
  jobDescription: "",
  generationOptions: {
    resume: true,
    coverLetter: true,
    references: false,
    recruiterMessage: false,
    linkedinOptimization: false,
    interviewPrep: false,
    networkingEmails: false,
    careerDevelopmentPlan: false,
    followUpEmails: false,
    salaryNegotiation: false,
    professionalBio: false,
    personalBranding: false,
    skillGapAnalysis: false,
    companyResearch: false,
  },
};

const TargetJobEntry = React.memo(
  ({
    index,
    jobInfo,
    handleChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
  }) => {
    const classes = useStyles();

    const onFieldChange = useCallback(
      (name, value) => {
        handleChange(index, name, value);
      },
      [handleChange, index]
    );

    const onRemoveEntry = useCallback(() => {
      handleRemoveEntry(index);
    }, [handleRemoveEntry, index]);

    const formFields = useMemo(
      () => [
        {
          section: "Job Details",
          fields: [
            {
              name: "jobTitle",
              label: "Job Title",
              required: true,
              icon: WorkIcon,
              maxLength: 100,
            },
            {
              name: "companyName",
              label: "Company Name",
              required: true,
              icon: BusinessIcon,
              maxLength: 100,
            },
            {
              name: "jobLocation",
              label: "Job Location",
              maxLength: 100,
            },
            {
              name: "jobType",
              label: "Job Type",
              required: true,
              select: true,
              options: [
                { value: "Full-time", label: "Full-time" },
                { value: "Part-time", label: "Part-time" },
                { value: "Contract", label: "Contract" },
                { value: "Internship", label: "Internship" },
                { value: "Temporary", label: "Temporary" },
              ],
            },
          ],
        },
        {
          section: "Salary and Deadline",
          fields: [
            {
              name: "salary",
              label: "Salary",
              type: "text",
            },
            {
              name: "applicationDeadline",
              label: "",
              type: "date",
              helperText: "Enter the deadline for submitting your application",
            },
          ],
        },
        {
          section: "Job Description",
          fields: [
            {
              name: "jobDescription",
              label: "Job Description",
              required: true,
              multiline: true,
              rows: 8,
              fullWidth: true,
              maxLength: 2000,
            },
          ],
        },
      ],
      []
    );

    const generationOptions = [
      {
        name: "resume",
        label: "Resume",
        tooltip: "Generate a tailored resume for this job",
      },
      {
        name: "coverLetter",
        label: "Cover Letter",
        tooltip: "Create a customized cover letter",
      },
      {
        name: "references",
        label: "References",
        tooltip: "Prepare a list of professional references",
      },
      {
        name: "recruiterMessage",
        label: "Message to Recruiter",
        tooltip: "Generate a personalized message for LinkedIn recruiters",
      },
      {
        name: "linkedinOptimization",
        label: "LinkedIn Profile Optimization",
        tooltip: "Get suggestions to improve your LinkedIn profile",
      },
      {
        name: "interviewPrep",
        label: "Interview Preparation",
        tooltip:
          "Prepare for job interviews with tailored questions and answers",
      },
      {
        name: "networkingEmails",
        label: "Networking Email Templates",
        tooltip: "Create templates for networking emails",
      },
      {
        name: "careerDevelopmentPlan",
        label: "Career Development Plan",
        tooltip: "Get a personalized career development plan",
      },
      {
        name: "followUpEmails",
        label: "Job Application Follow-up Emails",
        tooltip: "Generate follow-up emails for your job application",
      },
      {
        name: "salaryNegotiation",
        label: "Salary Negotiation Scripts",
        tooltip: "Receive scripts for salary negotiations",
      },
      {
        name: "professionalBio",
        label: "Professional Bio Creation",
        tooltip: "Create a professional bio for various platforms",
      },
      {
        name: "personalBranding",
        label: "Personal Branding Tips",
        tooltip: "Get tips for building your personal brand",
      },
      {
        name: "skillGapAnalysis",
        label: "Skill Gap Analysis",
        tooltip: "Identify and address skill gaps for this job",
      },
      {
        name: "companyResearch",
        label: "Company Research Reports",
        tooltip: "Get detailed reports on the target company",
      },
    ];

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader title={`Target Job ${index + 1}`} />
        {formFields.map(({ section, fields }) => (
          <React.Fragment key={section}>
            <SubsectionHeader title={section} />
            <Grid container spacing={3}>
              {fields.map((field) => (
                <Grid
                  item
                  xs={12}
                  sm={field.fullWidth ? 12 : 6}
                  key={field.name}
                >
                  <FormField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    value={jobInfo[field.name] || ""}
                    onChange={(name, value) => onFieldChange(name, value)}
                    error={errors[field.name]}
                    tooltip={`Enter the ${field.label.toLowerCase()}`}
                    aria-label={field.label}
                    placeholder={`Enter ${field.label.toLowerCase()}`}
                    maxLength={field.maxLength}
                    type={field.type}
                    multiline={field.multiline}
                    rows={field.rows}
                    fullWidth
                    select={field.select}
                    options={field.options}
                    icon={field.icon ? <field.icon /> : null}
                  >
                    {field.select &&
                      field.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </FormField>
                  {field.type === "date" && (
                    <DateHelperText text={field.helperText} />
                  )}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
        <SubsectionHeader title="Job Application Toolkit:" />
        <Grid container spacing={2} className={classes.checkboxGrid}>
          {generationOptions.map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.name}>
              <Tooltip title={option.tooltip} placement="top">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobInfo.generationOptions[option.name]}
                      onChange={(e) =>
                        onFieldChange(
                          `generationOptions.${option.name}`,
                          e.target.checked
                        )
                      }
                      name={option.name}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      className={classes.checkboxLabel}
                    >
                      {option.label}
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this target job">
            <IconButton
              onClick={onRemoveEntry}
              color="secondary"
              aria-label="Remove target job"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another target job">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add target job"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

export const TargetJobInformation = React.memo(() => {
  const classes = useStyles();
  const {
    formData,
    errors,
    updateFormData,
    setTargetJobInformation,
    validateFieldAndUpdateErrors,
  } = useContext(FormContext);

  const [alertMessage, setAlertMessage] = useState(null);
  const [showGeneratedContent, setShowGeneratedContent] = useState(false);
  const [generatedContent, setGeneratedContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef(null);

  const countToolkitSelections = useMemo(
    () => (jobInfoArray) => {
      return jobInfoArray.reduce((total, job) => {
        return (
          total + Object.values(job.generationOptions).filter(Boolean).length
        );
      }, 0);
    },
    []
  );

  useEffect(() => {
    const storedData = localStorage.getItem("targetJobInformation");
    if (
      storedData &&
      (!formData.targetJobInformation ||
        formData.targetJobInformation.length === 0)
    ) {
      setTargetJobInformation(JSON.parse(storedData));
    }
  }, [setTargetJobInformation, formData.targetJobInformation]);

  const handleChange = useCallback(
    (index, name, value) => {
      updateFormData("targetJobInformation", (prev) => {
        const updated = Array.isArray(prev) ? [...prev] : [];
        if (name.startsWith("generationOptions.")) {
          const [, optionName] = name.split(".");
          if (
            value &&
            countToolkitSelections(updated) >= MAX_TOOLKIT_SELECTIONS
          ) {
            setAlertMessage(
              `You can only select up to ${MAX_TOOLKIT_SELECTIONS} toolkit options in total.`
            );
            return prev;
          }
          updated[index] = {
            ...updated[index],
            generationOptions: {
              ...updated[index].generationOptions,
              [optionName]: value,
            },
          };
        } else {
          updated[index] = { ...updated[index], [name]: value };
        }
        localStorage.setItem("targetJobInformation", JSON.stringify(updated));
        return updated;
      });
      validateFieldAndUpdateErrors(
        `targetJobInformation[${index}]`,
        name,
        value
      );
    },
    [updateFormData, validateFieldAndUpdateErrors, countToolkitSelections]
  );

  const handleAddEntry = useCallback(() => {
    updateFormData("targetJobInformation", (prev) => {
      if (prev.length >= MAX_JOB_ENTRIES) {
        setAlertMessage(
          `You can only add up to ${MAX_JOB_ENTRIES} target jobs.`
        );
        return prev;
      }
      const updated = Array.isArray(prev)
        ? [...prev, initialTargetJobInfo]
        : [initialTargetJobInfo];
      localStorage.setItem("targetJobInformation", JSON.stringify(updated));
      return updated;
    });
  }, [updateFormData]);

  const handleRemoveEntry = useCallback(
    (index) => {
      updateFormData("targetJobInformation", (prev) => {
        if (prev.length === 1) {
          return [initialTargetJobInfo];
        }
        const updated = prev.filter((_, i) => i !== index);
        localStorage.setItem("targetJobInformation", JSON.stringify(updated));
        return updated;
      });
    },
    [updateFormData]
  );

  const handleContentGenerated = useCallback((content) => {
    setGeneratedContent(content);
    setShowGeneratedContent(true);
    setIsLoading(false);
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }, []);

  const targetJobInformation = Array.isArray(formData.targetJobInformation)
    ? formData.targetJobInformation
    : [initialTargetJobInfo];

  const renderGeneratedContent = useCallback(() => {
    return formData.targetJobInformation.map((job, jobIndex) => (
      <Box key={jobIndex} className={classes.materialContainer}>
        {isLoading ? (
          Object.entries(job.generationOptions)
            .filter(([key, value]) => value)
            .map(([key, value]) => (
              <ApplicationMaterialSkeleton
                key={key}
                title={materialTypeMap[key] || key}
              />
            ))
        ) : (
          <GeneratedContentDisplay
            isVisible={showGeneratedContent}
            generatedContent={generatedContent[jobIndex]}
            jobDetails={{
              jobTitle: job.jobTitle || "Job",
              companyName: job.companyName || "Company",
            }}
            updateGeneratedContent={(key, newValue) => {
              const updatedContent = [...generatedContent];
              updatedContent[jobIndex] = {
                ...updatedContent[jobIndex],
                [key]: newValue,
              };
              setGeneratedContent(updatedContent);
            }}
            isLoading={isLoading}
            generationOptions={job.generationOptions}
          />
        )}
        {jobIndex < formData.targetJobInformation.length - 1 && <Divider />}
      </Box>
    ));
  }, [
    formData.targetJobInformation,
    isLoading,
    showGeneratedContent,
    generatedContent,
    classes,
  ]);

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <SectionHeader title="Target Job Information" />
      <div className={classes.formContent}>
        <div className={classes.formContainer}>
          {targetJobInformation.map((jobInfo, index) => (
            <TargetJobEntry
              key={index}
              index={index}
              jobInfo={jobInfo}
              handleChange={handleChange}
              handleRemoveEntry={handleRemoveEntry}
              handleAddEntry={handleAddEntry}
              errors={errors[`targetJobInformation[${index}]`] || {}}
            />
          ))}
        </div>
        <Divider className={classes.divider} />
        <div className={classes.generateButtonContainer}>
          <GenerateApplicationMaterialsButton
            onContentGenerated={handleContentGenerated}
            onGenerateStart={() => setIsLoading(true)}
          />
        </div>
      </div>
      {(isLoading || showGeneratedContent) && (
        <div className={classes.generatedContentContainer} ref={contentRef}>
          {renderGeneratedContent()}
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert onClose={() => setAlertMessage(null)} severity="warning">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={!!errors.submit}
        autoHideDuration={6000}
        onClose={() => updateFormData("errors", { submit: null })}
      >
        <Alert
          onClose={() => updateFormData("errors", { submit: null })}
          severity="error"
        >
          {errors.submit}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default TargetJobInformation;
