import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Person,
  School,
  Work,
  Assignment,
  Stars,
} from "@material-ui/icons";
import { useAuth } from "./../util/auth";
import {
  generateResumeCoverLetter,
  replaceName,
  currentDate,
} from "./../util/util";
import { FormProvider } from "./FormContext";
import { PersonalInformation } from "./FormSection1_PersonalInformation.js";
import { WorkExperience } from "./FormSection3_WorkExperience.js";
import { Education } from "./FormSection2_Education.js";
import { TargetJobInformation } from "./FormSection5_TargetJobInformation.js";
import { AdditionalQualifications } from "./FormSection4_AdditionalQualifications.js";
import PrintFormDataButton from "./GenerateApplicationMaterialsButton.js";
import UploadResumeButton from "./UploadResumeButton";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // console.log("Error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    minHeight: 48,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      borderRadius: "10px 10px 0 0",
    },
  },
  selectedTab: {
    backgroundColor: "#3f51b5",
    color: "#fff !important",
    fontWeight: "bold",
    borderRadius: "10px 10px 0 0",
  },
  unselectedTab: {
    backgroundColor: "#f5f5f5",
    color: "#888",
  },
  indicator: {
    display: "none",
  },
  tabContent: {
    flexDirection: "column",
    position: "relative",
  },
  progressBar: {
    height: "12px",
    backgroundColor: "#d1d1d1",
    position: "absolute",
    top: 55,
    width: "100%",
    zIndex: 1,
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#3f51b5",
      boxShadow: "0 0 8px rgba(209, 209, 209, 0.6)",
      animation: "none",
    },
    transition: "width 0.5s ease-in-out, background 0.5s ease-in-out",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    top: 15,
    width: "calc(100% - 40px)",
    left: "20px",
    zIndex: 1,
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#fff",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  formContent: {
    zIndex: 0,
  },
  tabIcon: {
    fontSize: "2rem",
  },
  uploadButtonContainer: {
    position: "absolute",
    right: -65,
    top: 120,
    zIndex: 1000,
  },
  uploadButtonMobile: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    background: `linear-gradient(to top, ${theme.palette.primary.dark}, transparent)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "100px",
    pointerEvents: "none",
  },
  uploadButtonInnerMobile: {
    pointerEvents: "auto",
  },
}));

function JobDetailsForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);

  function formatName(str) {
    return str.toLowerCase().replace(/\s/g, "");
  }

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const renderSection = () => {
    switch (tabIndex) {
      case 0:
        return <PersonalInformation />;
      case 1:
        return (
          <ErrorBoundary>
            <Education />
          </ErrorBoundary>
        );
      case 2:
        return (
          <ErrorBoundary>
            <WorkExperience />
          </ErrorBoundary>
        );
      case 3:
        return (
          <ErrorBoundary>
            <AdditionalQualifications />
          </ErrorBoundary>
        );
      case 4:
        return <TargetJobInformation />;
      default:
        return <PersonalInformation />;
    }
  };

  const progress = (tabIndex + 1) * 20;

  return (
    <Box position="relative">
      <FormProvider>
        {!isMobile && (
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            classes={{ root: classes.tabs, indicator: classes.indicator }}
          >
            <Tab
              icon={<Person className={classes.tabIcon} />}
              aria-label="Contact Information"
              classes={{
                root: classes.tabRoot,
                selected:
                  tabIndex === 0 ? classes.selectedTab : classes.unselectedTab,
              }}
            />
            <Tab
              icon={<School className={classes.tabIcon} />}
              aria-label="Education History"
              classes={{
                root: classes.tabRoot,
                selected:
                  tabIndex === 1 ? classes.selectedTab : classes.unselectedTab,
              }}
            />
            <Tab
              icon={<Work className={classes.tabIcon} />}
              aria-label="Work Experience"
              classes={{
                root: classes.tabRoot,
                selected:
                  tabIndex === 2 ? classes.selectedTab : classes.unselectedTab,
              }}
            />
            <Tab
              icon={<Stars className={classes.tabIcon} />}
              aria-label="Additional Qualifications"
              classes={{
                root: classes.tabRoot,
                selected:
                  tabIndex === 3 ? classes.selectedTab : classes.unselectedTab,
              }}
            />
            <Tab
              icon={<Assignment className={classes.tabIcon} />}
              aria-label="Target Job Details"
              classes={{
                root: classes.tabRoot,
                selected:
                  tabIndex === 4 ? classes.selectedTab : classes.unselectedTab,
              }}
            />
          </Tabs>
        )}
        <Box className={classes.tabContent}>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.progressBar}
          />
          {renderSection()}
          <Box className={classes.buttonContainer}>
            <Typography
              className={classes.iconText}
              onClick={() => setTabIndex(tabIndex - 1)}
              style={{ visibility: tabIndex === 0 ? "hidden" : "visible" }}
            >
              <ArrowBackIos />
              {!isMobile && "Back"}
            </Typography>
            <Typography
              className={classes.iconText}
              onClick={() => setTabIndex(tabIndex + 1)}
              style={{ visibility: tabIndex === 4 ? "hidden" : "visible" }}
            >
              {!isMobile && "Next"}
              <ArrowForwardIos />
            </Typography>
          </Box>
        </Box>
        {isMobile ? (
          <Box className={classes.uploadButtonMobile}>
            <Box className={classes.uploadButtonInnerMobile}>
              <UploadResumeButton isMobile={true} />
            </Box>
          </Box>
        ) : (
          <Box className={classes.uploadButtonContainer}>
            <UploadResumeButton isMobile={false} />
          </Box>
        )}
      </FormProvider>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
    </Box>
  );
}

export default JobDetailsForm;
