// In this dashboard.js file, a DashboardPage React component is being defined and exported. 
// This component displays a Meta component to set the page's metadata and a DashboardSection component to render 
// the main content of the dashboard page. The DashboardPage component is wrapped with a requireAuth function 
// that is imported from the auth module in the util folder. This function checks if 
// the user is authenticated before allowing them to access the dashboard page. 
// If the user is not authenticated, they will be redirected to the login page.

import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(DashboardPage);
