import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import PoweredBy from "../components/PoweredBy";
import FeaturesSection from "./../components/FeaturesSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import { useDarkMode } from "../util/theme";
import { Fade } from "react-awesome-reveal";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function IndexPage(props) {
  const { value: isDarkMode } = useDarkMode();
  const isDesktop = useMediaQuery("(min-width:960px)");
  console.log("Is Desktop:", isDesktop);

  return (
    <>
      <Meta />
      <Fade triggerOnce={false}>
        <HeroSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Supercharge Your Job Search with AI-Powered Applications"
          subtitle="Apply to 10 jobs in the time it takes to apply to one. Our AI crafts tailored resumes, cover letters, and 10+ application materials for each position. Stand out, save time, and land your dream job faster."
          buttonText="Boost My Job Applications"
          buttonColor="primary"
          buttonPath="/pro"
          showFlameAnimation={isDesktop} // Add this line
        />
      </Fade>
      <Fade triggerOnce={false}>
        <PoweredBy
          bgColor="light"
          size="normal"
          bgImage=""
          bgImageOpacity={1}
          title="Powered by the World's Most Advanced Language Model: ChatGPT"
          subtitle="Experience the highest quality resume and cover letter creation with the help of OpenAI's ChatGPT technology"
        />
      </Fade>
      <Fade triggerOnce={false}>
        <FeaturesSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Features"
          subtitle="All the tools you need to accelerate your job search"
          buttonText="Boost My Job Applications"
          buttonColor="primary"
          buttonPath="/pro"
        />
      </Fade>
      <Fade triggerOnce={false}>
        <TestimonialsSection
          bgColor="light"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Here's what people are saying"
          subtitle=""
        />
      </Fade>
      <Fade triggerOnce={false}>
        <NewsletterSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Stay in the know"
          subtitle="Receive our latest articles and feature updates"
          buttonText="Subscribe"
          buttonColor="primary"
          inputPlaceholder="Enter your email"
          subscribedMessage="You are now subscribed!"
        />
      </Fade>
    </>
  );
}

export default IndexPage;
