import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useDarkMode } from "../util/theme";
import HeroImage from "./../images/openai_light_mode.png";
import DarkHeroImage from "./../images/openai_dark_mode.png";

function PoweredBy(props) {
  const { value: isDarkMode } = useDarkMode();
  const items = [
    {
      name: "OpenAI",
      image: isDarkMode ? DarkHeroImage : HeroImage,
      text: "OpenAI",
      width: "75px",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />
          <Grid container={true} justifyContent="center">
            {items.map((item, index) => (
              <Grid item={true} xs={12} md="auto" key={index}>
                <Box py={2} px={3}>
                  <img src={item.image} width={item.width} alt={item.name} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default PoweredBy;
