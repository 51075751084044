import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  preformattedText: {
    whiteSpace: "pre-wrap",
  },
}));

function BioSection(props) {
  const classes = useStyles();

  const items = [
    {
      avatar:
        "https://live.staticflickr.com/65535/52596685521_6054de480d_q.jpg",
      name: "Kylan Thomson",
      role: "Software Engineer",
      bio: "\nHello!\n\nI'm Kylan Thomson,\n\nThe single force behind Resume Revival. As a full-stack developer, I'm driven by the power of technology to revolutionize the way we approach complex tasks, such as job applications.\n\nFrom an early age, the ability of software to drive transformative change fascinated me. This led me to build Resume Revival - a free-to-use, AI-driven platform committed to creating bespoke, ATS-optimized resumes and cover letters.\n\nI'm delighted to apply my comprehensive skill set and industry insights to assist others in enhancing their professional pursuits and securing their desired roles. In my downtime, I thrive on staying updated with emerging tech trends and mastering new programming languages and frameworks.\n\nI'm excited to create a positive ripple effect in your career journey through my brainchild, Resume Revival.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card}>
                <CardContent>
                  <Box className={classes.avatarWrapper}>
                    <Avatar
                      src={item.avatar}
                      alt={item.name}
                      className={classes.avatar}
                    />
                  </Box>
                  <Box textAlign="center" pt={3}>
                    <Typography variant="body2" component="p">
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.role}
                    </Typography>
                    <Box mt={2} textAlign="left">
                      <Typography
                        variant="body1"
                        component="pre"
                        className={classes.preformattedText}
                      >
                        {item.bio}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default BioSection;
