// FormSection1_PersonalInformation.js
import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { FormContext } from "./FormContext";
import { Grid, Paper, Typography, Snackbar, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useStyles } from "./styles";
import { validatePersonalInfo, validateField } from "./validation";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import WorkIcon from "@material-ui/icons/Work";
import { FormField } from "./FormComponents";

const SectionHeader = React.memo(({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionHeaderContainer}>
      <Typography variant="h5" className={classes.sectionHeaderText}>
        {title}
      </Typography>
    </div>
  );
});

const SubsectionHeader = React.memo(({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.subsectionHeader}>
      {title}
    </Typography>
  );
});

export const PersonalInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [autoSaveMessage, setAutoSaveMessage] = useState("");
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // console.log("PersonalInformation formData:", formData); // Add this log
  }, [formData]);

  const handleChange = useCallback(
    (name, value) => {
      // console.log(`PersonalInformation handleChange: ${name}`, value);
      const updatedData = { ...formData.personalInformation, [name]: value };
      // console.log("Updated Personal Information Data:", updatedData); // Add this log
      updateFormData("personalInformation", updatedData);
      try {
        localStorage.setItem(
          "personalInformation",
          JSON.stringify(updatedData)
        );
        if (isMounted.current) {
          setAutoSaveMessage("Auto-saving...");
          setTimeout(() => {
            if (isMounted.current) {
              setAutoSaveMessage("");
            }
          }, 2000);
        }
      } catch (error) {
        console.error("Error saving to localStorage:", error);
        if (isMounted.current) {
          setAutoSaveMessage("Auto-save failed");
          setTimeout(() => {
            if (isMounted.current) {
              setAutoSaveMessage("");
            }
          }, 2000);
        }
      }

      const fieldError = validateField(name, value, formData);
      if (isMounted.current) {
        setErrors((prev) => ({ ...prev, [name]: fieldError }));
      }
    },
    [formData, updateFormData]
  );

  const handleSave = useCallback(() => {
    const validationErrors = validatePersonalInfo(formData.personalInformation);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // console.log("Save and Continue clicked");
      // console.log("Form Data being saved:", formData); // Add this log
      // Add your save logic here
    }
  }, [formData]);

  const formFields = useMemo(
    () => [
      {
        section: "Personal Information",
        fields: [
          {
            name: "firstName",
            label: "First Name",
            required: true,
            icon: PersonIcon,
          },
          {
            name: "lastName",
            label: "Last Name",
            required: true,
          },
        ],
      },
      {
        section: "Address",
        fields: [
          {
            name: "streetAddress",
            label: "Street Address",
            required: true,
            icon: HomeIcon,
            fullWidth: true,
          },
          { name: "city", label: "City", required: true },
          { name: "state", label: "State/Province", required: true },
          { name: "zipCode", label: "Zip/Postal Code", required: true },
          { name: "country", label: "Country", required: true },
        ],
      },
      {
        section: "Contact Information",
        fields: [
          {
            name: "phone",
            label: "Phone",
            required: true,
            type: "tel",
            icon: PhoneIcon,
          },
          {
            name: "email",
            label: "Email Address",
            required: true,
            type: "email",
            icon: EmailIcon,
          },
        ],
      },
      {
        section: "Online Presence",
        fields: [
          {
            name: "linkedinUrl",
            label: "LinkedIn Profile URL",
            icon: LinkedInIcon,
          },
          {
            name: "websiteUrl",
            label: "Website/Portfolio URL",
            icon: LanguageIcon,
          },
          {
            name: "twitterHandle",
            label: "X (Twitter) Handle",
            icon: TwitterIcon,
          },
          {
            name: "githubUsername",
            label: "GitHub Username",
            icon: GitHubIcon,
          },
          {
            name: "otherSocialProfile",
            label: "Other Social Profile",
            fullWidth: true,
          },
        ],
      },
      {
        section: "Professional Information",
        fields: [
          {
            name: "professionalTitle",
            label: "Professional Title",
            icon: WorkIcon,
            fullWidth: true,
          },
          {
            name: "summary",
            label: "Summary/Objective Statement",
            multiline: true,
            rows: 4,
            fullWidth: true,
            maxLength: 800,
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    // console.log("formData.personalInformation:", formData.personalInformation);
    // console.log(
    //   "formData.targetJobInformation:",
    //   formData.targetJobInformation
    // );
  }, [formData]);

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <SectionHeader title="Basic Information" />
      <div className={classes.formContent}>
        <div className={classes.formInnerContent}>
          <form noValidate autoComplete="off">
            {formFields.map(({ section, fields }) => (
              <React.Fragment key={section}>
                <SubsectionHeader title={section} />
                <Grid container spacing={3} className={classes.formSection}>
                  {fields.map((field) => (
                    <FormField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      type={field.type}
                      value={formData.personalInformation[field.name] || ""}
                      onChange={handleChange}
                      error={errors[field.name]}
                      tooltip={`Enter your ${field.label.toLowerCase()}`}
                      icon={field.icon ? <field.icon /> : null}
                      fullWidth={field.fullWidth}
                      multiline={field.multiline}
                      rows={field.rows}
                      maxLength={field.maxLength}
                    />
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={!!autoSaveMessage}
        autoHideDuration={2000}
        onClose={() => setAutoSaveMessage("")}
      >
        <Alert
          onClose={() => setAutoSaveMessage("")}
          severity={autoSaveMessage.includes("failed") ? "error" : "success"}
        >
          {autoSaveMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(PersonalInformation);
