import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormContext } from "./FormContext";
import { generateApplicationMaterials } from "./apiService";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "inline-block",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  button: {
    minWidth: 250,
    height: 56,
    borderRadius: 28,
    textTransform: "none",
    fontSize: "1.1rem",
    fontWeight: 600,
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow:
        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
    },
  },
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  loadingIndicator: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
}));

const GenerateApplicationMaterialsButton = ({
  onContentGenerated,
  onGenerateStart,
}) => {
  const classes = useStyles();
  const { formData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateContent = useCallback(async () => {
    setIsLoading(true);
    onGenerateStart();

    try {
      const targetJobs = formData.targetJobInformation || [];
      console.log("formData:", JSON.stringify(formData, null, 2));
      console.log(
        "Generating materials for jobs:",
        JSON.stringify(targetJobs, null, 2)
      );
      const results = await generateApplicationMaterials(formData, targetJobs);
      console.log("Generated results:", results);
      onContentGenerated(results);
    } catch (error) {
      console.error("Error calling Google Cloud Functions:", error);
      // Show an error message to the user
      alert(
        "An error occurred while generating application materials. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }, [formData, onContentGenerated, onGenerateStart]);

  return (
    <div className={classes.buttonWrapper}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateContent}
        disabled={isLoading}
        className={classes.button}
        aria-label={
          isLoading
            ? "Generating application materials"
            : "Generate application materials"
        }
        startIcon={
          isLoading && (
            <CircularProgress size={20} className={classes.loadingIndicator} />
          )
        }
      >
        <Typography variant="button" className={classes.buttonText}>
          {isLoading ? "Generating..." : "Generate Application Materials"}
        </Typography>
      </Button>
    </div>
  );
};

GenerateApplicationMaterialsButton.propTypes = {
  onContentGenerated: PropTypes.func.isRequired,
  onGenerateStart: PropTypes.func.isRequired,
};

export default React.memo(GenerateApplicationMaterialsButton);
