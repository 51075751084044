// The FaqPage function is a React component that renders a Meta component and a FaqSection component. 
// It does not have any additional logic. When this component is rendered, it will display the 
// Meta component with a title of "Faq" and the FaqSection component with 
// the specified background color, size, background image and opacity, title, and subtitle.

import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";

function FaqPage(props) {
  return (
    <>
      <Meta title="Faq" />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default FaqPage;
