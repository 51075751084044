import ReactGA from "react-ga4";
import { history } from "./router.js";

// Initialize ReactGA
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

// Track initial pageview
if (typeof window !== "undefined") {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
}

// Track pageview on route change
history.listen((location) => {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname + location.search,
  });
});

export default ReactGA;
