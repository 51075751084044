import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import JobDetailsForm from "./JobDetailsForm";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  customTitle: {
    fontSize: "2.5rem",
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  customSubtitle: {
    fontSize: "1.4rem",
    maxWidth: 600,
    margin: "0 auto",
    textAlign: "center",
    marginBottom: theme.spacing(6), // Increased space between subtitle and form
  },
  formWrapper: {
    // Remove marginTop: 'auto' to prevent pushing to bottom
  },
}));

function JobDetails(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={classes.fullHeight}
    >
      <Container maxWidth="md" className={classes.contentWrapper}>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
          titleClassName={classes.customTitle}
          subtitleClassName={classes.customSubtitle}
        />
        <div className={classes.formWrapper}>
          <JobDetailsForm
            showNameField={props.showNameField}
            buttonText={props.buttonText}
            buttonColor={props.buttonColor}
          />
        </div>
      </Container>
    </Section>
  );
}

export default JobDetails;
