import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import ai_robot from "./../images/Artificial_Intelligence_Powered.png";
import skill_gap from "./../images/Skill_Gap_Analysis.png";
import custom_options from "./../images/Customization_Options.png";
import unlock_opportunity from "./../images/Unlock_Opportunity.png";
import { Link } from "./../util/router";
import Button from "@material-ui/core/Button";
import { useAuth } from "./../util/auth";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 450,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "25px",
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "12px 24px",
    fontSize: "1.1rem",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "30px",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: theme.palette.primary.dark,
      transform: "translateY(-2px)",
      boxShadow:
        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
    },
    "&:active": {
      transform: "translateY(1px)",
    },
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
    fontSize: "1.3rem",
    transition: "transform 0.3s ease",
  },
  buttonWrapper: {
    display: "inline-block",
    "&:hover .MuiSvgIcon-root": {
      transform: "translateX(4px)",
    },
  },
}));

function FeaturesSection(props) {
  const auth = useAuth();
  const classes = useStyles();

  const items = [
    {
      title: "AI-Powered Multi-Job Tailoring",
      description:
        "Customize your applications for up to 10 different positions simultaneously. Our AI adapts your resume and cover letter to match each job's specific requirements, increasing your chances of success.",
      image: ai_robot,
    },
    {
      title: "Comprehensive Application Toolkit",
      description:
        "Generate up to 20 essential job application materials per position, including resumes, cover letters, follow-up emails, and more. Everything you need for a complete, professional application package.",
      image: custom_options,
    },
    {
      title: "Skill Gap Analysis and Career Optimization",
      description:
        "Identify areas for improvement with our advanced skill gap analysis. Align your qualifications with job market demands and receive personalized recommendations to enhance your career prospects.",
      image: skill_gap,
    },
    {
      title: "Time-Saving Automation",
      description:
        "Save hours of work with our efficient, AI-driven process. Create tailored, high-quality application materials for multiple jobs in minutes, allowing you to apply to more positions and increase your odds of landing interviews.",
      image: unlock_opportunity,
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
        <Box display="flex" justifyContent="center" mt={4}>
          <div className={classes.buttonWrapper}>
            <Button
              component={Link}
              to={
                auth.user && auth.user.planId === "pro"
                  ? `/${auth.user.planId}`
                  : props.buttonPath
              }
              variant="contained"
              size="large"
              className={classes.button}
              endIcon={<ArrowForwardIcon className={classes.buttonIcon} />}
            >
              {props.buttonText}
            </Button>
          </div>
        </Box>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
