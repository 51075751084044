// FormSection4_AdditionalQualifications.js
import React, { useContext, useState } from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormField, SectionHeader } from "./FormComponents";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WorkIcon from "@material-ui/icons/Work";
import SecurityIcon from "@material-ui/icons/Security";
import SchoolIcon from "@material-ui/icons/School";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import SearchIcon from "@material-ui/icons/Search";
import GroupIcon from "@material-ui/icons/Group";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MilitaryIcon from "@material-ui/icons/Stars";
import CreateIcon from "@material-ui/icons/Create";

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formInnerContent: {
    padding: theme.spacing(3),
  },
  qualificationCard: {
    marginBottom: theme.spacing(2),
  },
  qualificationTypeGrid: {
    marginBottom: theme.spacing(3),
  },
  qualificationTypeCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  stepper: {
    margin: "16px 0",
  },
}));

const qualificationTypes = [
  {
    type: "Military Service",
    description: "Add details about your military service experience.",
    icon: MilitaryIcon,
  },
  {
    type: "Work Authorization",
    description: "Provide information about your work authorization status.",
    icon: WorkIcon,
  },
  {
    type: "Security Clearance",
    description: "Specify your security clearance level.",
    icon: SecurityIcon,
  },
  {
    type: "Personal Project",
    description:
      "Share information about your personal initiatives or passion projects.",
    icon: CreateIcon,
  },
  {
    type: "Certification",
    description:
      "Add details about your professional licenses, certifications, or other credentials.",
    icon: SchoolIcon,
  },
  {
    type: "Patent",
    description: "Provide information about patents you hold.",
    icon: EmojiObjectsIcon,
  },
  {
    type: "Research Experience",
    description: "Describe your research experience and projects.",
    icon: SearchIcon,
  },
  {
    type: "Volunteering",
    description: "Share details about your community service activities.",
    icon: GroupIcon,
  },
  {
    type: "Professional Development",
    description:
      "Workshops, seminars, courses, or any other professional development activities.",
    icon: MenuBookIcon,
  },
];

const fieldsByType = {
  "Military Service": [
    {
      name: "branch",
      label: "Branch of Service",
      required: true,
      select: true,
      options: [
        "Army",
        "Navy",
        "Air Force",
        "Marine Corps",
        "Coast Guard",
        "Space Force",
      ],
      icon: MilitaryIcon,
      tooltip: "Select the branch of military service you served in",
    },
    {
      name: "rank",
      label: "Rank",
      required: true,
      icon: MilitaryIcon,
      tooltip: "Enter your highest achieved rank",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      icon: MilitaryIcon,
      tooltip: "Enter the date you started your military service",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
      icon: MilitaryIcon,
      tooltip: "Enter the date you ended your military service",
    },
    {
      name: "specialTraining",
      label: "Special Training or Skills",
      multiline: true,
      rows: 4,
      icon: MilitaryIcon,
      tooltip:
        "Describe any special training or skills acquired during your service",
    },
  ],
  "Work Authorization": [
    {
      name: "status",
      label: "Work Authorization Status",
      required: true,
      select: true,
      options: ["US Citizen", "Permanent Resident", "Work Visa", "Other"],
      icon: WorkIcon,
      tooltip: "Select your current work authorization status",
    },
  ],
  "Security Clearance": [
    {
      name: "level",
      label: "Security Clearance Level",
      required: true,
      select: true,
      options: ["Confidential", "Secret", "Top Secret", "Other"],
      icon: SecurityIcon,
      tooltip: "Select your highest level of security clearance",
    },
  ],
  "Personal Project": [
    {
      name: "projectName",
      label: "Project Name",
      required: true,
      icon: CreateIcon,
      tooltip: "Enter the name of your personal project",
    },
    {
      name: "description",
      label: "Project Description",
      multiline: true,
      rows: 4,
      required: true,
      icon: CreateIcon,
      tooltip: "Provide a brief description of your project and its goals",
    },
    {
      name: "skills",
      label: "Skills Utilized",
      required: true,
      icon: CreateIcon,
      tooltip: "List the main skills or techniques you used in this project",
    },
    {
      name: "outcome",
      label: "Project Outcome",
      multiline: true,
      rows: 2,
      icon: CreateIcon,
      tooltip: "Describe the results or impact of your project",
    },
    {
      name: "projectUrl",
      label: "Project URL",
      icon: CreateIcon,
      tooltip: "Enter a URL where your project can be viewed (if applicable)",
    },
  ],
  Certification: [
    {
      name: "credentialType",
      label: "Credential Type",
      required: true,
      select: true,
      options: ["License", "Certification", "Other"],
      icon: SchoolIcon,
      tooltip: "Select the type of professional credential",
    },
    {
      name: "name",
      label: "Credential Name",
      required: true,
      icon: SchoolIcon,
      tooltip: "Enter the name of your professional license or certification",
    },
    {
      name: "issuer",
      label: "Issuing Organization",
      required: true,
      icon: SchoolIcon,
      tooltip: "Enter the name of the organization that issued the credential",
    },
    {
      name: "dateObtained",
      label: "Date Obtained",
      type: "date",
      required: true,
      icon: SchoolIcon,
      tooltip: "Enter the date you obtained this credential",
    },
    {
      name: "expirationDate",
      label: "Expiration Date",
      type: "date",
      icon: SchoolIcon,
      tooltip: "Enter the expiration date of this credential, if applicable",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      rows: 3,
      icon: SchoolIcon,
      tooltip:
        "Provide a brief description of this credential and its relevance to your field",
    },
  ],
  Patent: [
    {
      name: "patentNumber",
      label: "Patent Number",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the patent number",
    },
    {
      name: "patentTitle",
      label: "Patent Title",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the title of the patent",
    },
    {
      name: "dateFiled",
      label: "Date Filed",
      type: "date",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the date the patent was filed",
    },
    {
      name: "dateGranted",
      label: "Date Granted",
      type: "date",
      icon: EmojiObjectsIcon,
      tooltip: "Enter the date the patent was granted",
    },
    {
      name: "description",
      label: "Patent Description",
      multiline: true,
      rows: 6,
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Describe your patent, including its purpose and impact",
    },
  ],
  "Research Experience": [
    {
      name: "projectTitle",
      label: "Project Title",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the title of the research project",
    },
    {
      name: "role",
      label: "Role",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter your role in the project",
    },
    {
      name: "institution",
      label: "Institution/Organization",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the name of the institution or organization",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the starting date of the research project",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the ending date of the research project",
    },
    {
      name: "description",
      label: "Project Description",
      multiline: true,
      rows: 4,
      required: true,
      icon: SearchIcon,
      tooltip: "Provide a detailed description of the research project",
    },
    {
      name: "outcomes",
      label: "Outcomes and Findings",
      multiline: true,
      rows: 3,
      icon: SearchIcon,
      tooltip: "Describe the outcomes or findings of the research project",
    },
  ],
  Volunteering: [
    {
      name: "organization",
      label: "Organization",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the name of the organization you volunteered with",
    },
    {
      name: "role",
      label: "Role",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter your role or position in the organization",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the date you started volunteering",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the date you ended volunteering",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      rows: 4,
      icon: GroupIcon,
      tooltip: "Describe your community service activities and achievements",
    },
  ],
  "Professional Development": [
    {
      name: "name",
      label: "Activity Name",
      required: true,
      icon: MenuBookIcon,
      tooltip: "Enter the name of the professional development activity",
    },
    {
      name: "type",
      label: "Activity Type",
      required: true,
      select: true,
      options: ["Workshop", "Seminar", "Course", "Conference", "Other"],
      icon: MenuBookIcon,
      tooltip: "Select the type of professional development activity",
    },
    {
      name: "date",
      label: "Date",
      type: "date",
      required: true,
      icon: MenuBookIcon,
      tooltip: "Enter the date of the activity",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      rows: 4,
      icon: MenuBookIcon,
      tooltip: "Provide a brief description of the activity",
    },
    {
      name: "location",
      label: "Location",
      icon: MenuBookIcon,
      tooltip: "Enter the location of the activity",
    },
    {
      name: "organizer",
      label: "Organizer",
      icon: MenuBookIcon,
      tooltip: "Enter the name of the organizing body",
    },
  ],
};

const QualificationForm = ({
  qualification: initialQualification,
  onSave,
  onCancel,
}) => {
  const classes = useStyles();
  const [qualification, setQualification] = useState(
    initialQualification || { qualificationType: "", details: {} }
  );
  const [currentStep, setCurrentStep] = useState(0);
  const fields = fieldsByType[qualification.qualificationType] || [];

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, fields.length - 1));
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleSave = () => {
    onSave(qualification);
  };

  const handleFieldChange = (name, value) => {
    setQualification((prev) => ({
      ...prev,
      details: {
        ...prev.details,
        [name]: value,
      },
    }));
  };

  return (
    <Box>
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        className={classes.stepper}
      >
        {fields.map((field) => (
          <Step key={field.name}>
            <StepLabel>{field.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={3}>
        {currentStep < fields.length ? (
          <FormField
            {...fields[currentStep]}
            value={qualification.details[fields[currentStep]?.name] || ""}
            onChange={(name, value) => handleFieldChange(name, value)}
            fullWidth
            aria-describedby={
              fields[currentStep].select
                ? undefined
                : `tooltip-${fields[currentStep]?.name}`
            }
          />
        ) : (
          <Typography>All steps completed</Typography>
        )}
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button disabled={currentStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Box>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          {currentStep === fields.length - 1 ? (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const QualificationCard = ({ qualification, onEdit, onDelete }) => {
  const classes = useStyles();

  if (!qualification) {
    return null;
  }

  const fields = fieldsByType[qualification.qualificationType] || [];

  return (
    <Card className={classes.qualificationCard}>
      <CardContent>
        <Typography variant="h6">{qualification.qualificationType}</Typography>
        {qualification.details &&
          Object.entries(qualification.details).map(([key, value]) => {
            const field = fields.find((f) => f.name === key);
            return field ? (
              <Typography key={key}>
                <strong>{field.label}:</strong> {value}
              </Typography>
            ) : null;
          })}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Tooltip title="Edit">
          <IconButton onClick={onEdit} size="small" aria-label="edit">
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={onDelete} size="small" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export const AdditionalQualifications = React.memo(() => {
  const classes = useStyles();
  const context = useContext(FormContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentQualification, setCurrentQualification] = useState(null);

  const handleAddQualification = (type) => {
    setCurrentQualification({
      qualificationType: type,
      details: {},
      index: undefined,
    });
    setIsDialogOpen(true);
  };

  const handleEditQualification = (index) => {
    const qualification = {
      ...context.formData.additionalQualifications[index],
      index,
    };
    setCurrentQualification(qualification);
    setIsDialogOpen(true);
  };

  const handleSaveQualification = (qualification) => {
    if (currentQualification.index !== undefined) {
      context.updateFormData("additionalQualifications", (prev) => {
        const updated = [...prev];
        updated[currentQualification.index] = qualification;
        return updated;
      });
    } else {
      context.updateFormData("additionalQualifications", (prev) => [
        ...(prev || []),
        qualification,
      ]);
    }
    setIsDialogOpen(false);
    setCurrentQualification(null);
  };

  const handleDeleteQualification = (index) => {
    context.updateFormData("additionalQualifications", (prev) =>
      prev.filter((_, i) => i !== index)
    );
  };

  const { formData } = context;

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <SectionHeader title="Additional Qualifications" />
      <div className={classes.formInnerContent}>
        <Box display="flex" justifyContent="flex-end" mb={2}></Box>
        <Grid container spacing={3} className={classes.qualificationTypeGrid}>
          {qualificationTypes.map((qualType) => (
            <Grid item xs={12} sm={6} md={4} key={qualType.type}>
              <Card className={classes.qualificationTypeCard}>
                <CardContent>
                  <Typography variant="h6">
                    {React.createElement(qualType.icon, {
                      style: { marginRight: "8px" },
                    })}
                    {qualType.type}
                  </Typography>
                  <Typography variant="body2">
                    {qualType.description}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => handleAddQualification(qualType.type)}
                    startIcon={<AddIcon />}
                    aria-label={`Add ${qualType.type}`}
                  >
                    Add
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {(formData.additionalQualifications || [])
          .filter((qualification) => qualification != null)
          .map((qualification, index) => (
            <QualificationCard
              key={index}
              qualification={qualification}
              onEdit={() => handleEditQualification(index)}
              onDelete={() => handleDeleteQualification(index)}
            />
          ))}
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {currentQualification?.index !== undefined ? "Edit" : "Add"}{" "}
            {currentQualification?.qualificationType}
          </DialogTitle>
          <DialogContent>
            {currentQualification && (
              <QualificationForm
                qualification={currentQualification}
                onSave={handleSaveQualification}
                onCancel={() => setIsDialogOpen(false)}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </Paper>
  );
});

export default AdditionalQualifications;
