import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
  Typography,
  Modal,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { AttachFile, Work, School, Person, Stars } from "@material-ui/icons";
import { FormContext } from "./FormContext";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "inline-flex",
    alignItems: "flex-start",
  },
  input: {
    display: "none",
  },
  iconButton: {
    padding: theme.spacing(1.5),
    "& svg": {
      fontSize: 45,
    },
  },
  iconButtonMobile: {
    backgroundColor: "white",
    borderRadius: "50%",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  iconMobile: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
  tooltip: {
    maxWidth: 350,
    fontSize: "1rem",
  },
  processingMessage: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalContent: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    outline: "none",
    textAlign: "center",
    zIndex: 1001,
    position: "relative",
    width: "90vw",
    maxWidth: "400px",
    maxHeight: "90vh",
    overflowY: "auto",
  },
  blurBackground: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(5px)",
    zIndex: 1000,
  },
  progressBar: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 10,
    borderRadius: 5,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(3),
  },
  icon: {
    fontSize: 40,
    color: theme.palette.grey[400],
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
  },
  activeIcon: {
    color: theme.palette.primary.main,
    transform: "scale(1.2)",
  },
  quickFillText: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    position: "absolute",
    bottom: -18,
    right: -5,
    backgroundColor: "white",
    padding: "2px 6px",
    borderRadius: 4,
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
  },
}));

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const UploadResumeButton = ({ isMobile }) => {
  const classes = useStyles();
  const { populateFormData } = useContext(FormContext);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [processingMessage, setProcessingMessage] = useState("");
  const [activeIcon, setActiveIcon] = useState(0);

  const resetState = () => {
    setUploading(false);
    setUploadError(null);
    setProgress(0);
    setProcessingMessage("");
    setActiveIcon(0);
  };

  useEffect(() => {
    let timer;
    if (uploading) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 350);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploading]);

  useEffect(() => {
    if (progress < 25) {
      setProcessingMessage("Getting basic information...");
      setActiveIcon(0);
    } else if (progress < 50) {
      setProcessingMessage("Retrieving education history...");
      setActiveIcon(1);
    } else if (progress < 75) {
      setProcessingMessage("Extracting work experience...");
      setActiveIcon(2);
    } else {
      setProcessingMessage("Gathering additional qualifications...");
      setActiveIcon(3);
    }
  }, [progress]);

  const handleFileUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];

      if (!file) return;

      if (file.size > MAX_FILE_SIZE) {
        setUploadError(
          "File size exceeds the 5MB limit. Please select a smaller file."
        );
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setUploadError(
          "Invalid file type. Please upload a PDF, DOC, or DOCX file."
        );
        return;
      }

      setUploading(true);
      setUploadError(null);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 35000);

        const response = await fetch(
          "https://us-central1-resumerevivalapp.cloudfunctions.net/convert_resume_to_json",
          {
            method: "POST",
            body: formData,
            signal: controller.signal,
          }
        );

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log("Cloud function response:", result);
        populateFormData(result);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadError(
          error.name === "AbortError"
            ? "Request timed out. Please try again."
            : "Failed to upload and process the file. Please try again."
        );
      } finally {
        resetState();
      }
    },
    [populateFormData]
  );

  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetState();
  };

  const tooltipContent = (
    <React.Fragment>
      <Typography variant="body2">
        Upload your existing resume (PDF, DOC, or DOCX, max 5MB) to quickly fill
        out the form.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 8 }}>
        Note: AI parsing may occasionally result in inaccuracies. Please review
        and edit as needed.
      </Typography>
    </React.Fragment>
  );

  return (
    <Box className={classes.container}>
      <input
        accept=".pdf,.doc,.docx"
        className={classes.input}
        id="icon-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="icon-button-file">
        <Tooltip title={tooltipContent} classes={{ tooltip: classes.tooltip }}>
          <IconButton
            color="primary"
            aria-label="upload resume"
            component="span"
            className={isMobile ? classes.iconButtonMobile : classes.iconButton}
            disabled={uploading}
          >
            {uploading ? (
              <CircularProgress size={24} />
            ) : (
              <AttachFile
                className={isMobile ? classes.iconMobile : undefined}
              />
            )}
          </IconButton>
        </Tooltip>
      </label>
      {!isMobile && (
        <Typography className={classes.quickFillText}>Quick Fill</Typography>
      )}
      <Modal
        open={uploading}
        className={classes.modal}
        onClose={handleModalClose}
        disableEscapeKeyDown
      >
        <div>
          <div className={classes.blurBackground} />
          <Paper className={classes.modalContent} elevation={3}>
            <CircularProgress size={60} />
            <Typography variant="h6" className={classes.processingMessage}>
              {processingMessage}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              className={classes.progressBar}
            />
            <div className={classes.iconContainer}>
              <Person
                className={`${classes.icon} ${
                  activeIcon === 0 ? classes.activeIcon : ""
                }`}
              />
              <School
                className={`${classes.icon} ${
                  activeIcon === 1 ? classes.activeIcon : ""
                }`}
              />
              <Work
                className={`${classes.icon} ${
                  activeIcon === 2 ? classes.activeIcon : ""
                }`}
              />
              <Stars
                className={`${classes.icon} ${
                  activeIcon === 3 ? classes.activeIcon : ""
                }`}
              />
            </div>
          </Paper>
        </div>
      </Modal>
      {uploadError && (
        <Alert severity="error" style={{ marginTop: 16 }}>
          {uploadError}
        </Alert>
      )}
    </Box>
  );
};

export default UploadResumeButton;
