// This plus.js file appears to define a page in a web application.
// The page consists of three components: JobDetails3, ChooseTemplate3, and StatsSection4.
// The JobDetails3 component seems to be related to displaying job-related information and includes a button with
// the text "Send message". The ChooseTemplate3 component appears to be related to selecting a template for something.
// The StatsSection4 component is likely related to displaying some kind of statistical information.

// The page is wrapped in a Meta component, which may be used to set the page title and other metadata.
// The page also appears to be protected by a requireAuth higher-order component, which means that a user
// must be authenticated in order to access the page.

import React from "react";
import Meta from "./../components/Meta";
import JobDetails from "./../components/JobDetails";
import StatsSection from "./../components/StatsSection";
import { requireAuth } from "./../util/auth";

function PlusPage(props) {
  return (
    <>
      <Meta title="Plus" />
      <JobDetails
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Job Details"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
      <StatsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default requireAuth(PlusPage);
