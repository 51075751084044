import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    boxSizing: "border-box",
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ApplicationMaterialSkeleton = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {title && (
        <Typography variant="h6">
          <Skeleton width="40%" />
        </Typography>
      )}
      <Box className={classes.content}>
        <Skeleton variant="rect" height={150} />
      </Box>
    </Box>
  );
};

export default ApplicationMaterialSkeleton;
