// FormSection3_WorkExperience.js
import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Snackbar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  SectionHeader,
  SubsectionHeader,
  FormField,
  DateHelperText,
} from "./FormComponents";
import WorkIcon from "@material-ui/icons/Work";
import BusinessIcon from "@material-ui/icons/Business";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formContent: {
    position: "relative",
  },
  formInnerContent: {
    padding: theme.spacing(3),
  },
  workExperienceEntries: {
    marginTop: theme.spacing(4),
  },
  noExperienceCheckbox: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  entryContainer: {
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  subsection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  currentlyEmployedCheckbox: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const initialWorkExperience = {
  jobTitle: "",
  companyName: "",
  location: "",
  startDate: "",
  endDate: "",
  currentlyEmployed: false,
  jobDescriptionAndAchievements: "",
  supervisorName: "",
  supervisorContact: "",
  reasonForLeaving: "",
  skillsUtilized: "",
  technologiesUsed: "",
};

const WorkExperienceEntry = React.memo(
  ({
    index,
    experience,
    handleChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
  }) => {
    const classes = useStyles();

    const onFieldChange = useCallback(
      (name, value) => {
        handleChange(index, name, value);
      },
      [handleChange, index]
    );

    const onRemoveEntry = useCallback(() => {
      handleRemoveEntry(index);
    }, [handleRemoveEntry, index]);

    const isCurrentlyEmployed = useMemo(
      () => experience.currentlyEmployed,
      [experience.currentlyEmployed]
    );

    const formFields = useMemo(
      () => [
        {
          section: "Job Details",
          fields: [
            {
              name: "jobTitle",
              label: "Job Title",
              required: true,
              icon: WorkIcon,
              fullWidth: true,
            },
            {
              name: "companyName",
              label: "Company Name",
              required: true,
              icon: BusinessIcon,
              fullWidth: true,
            },
            {
              name: "location",
              label: "Location (City, State)",
              required: true,
              fullWidth: true,
            },
            {
              name: "startDate",
              label: "",
              type: "date",
              required: true,
              helperText: "Enter the date you started this job",
            },
            {
              name: "endDate",
              label: "",
              type: "date",
              required: !isCurrentlyEmployed,
              hidden: isCurrentlyEmployed,
              helperText: "Enter the date you left this job",
            },
          ],
        },
        {
          section: "Responsibilities and Achievements",
          fields: [
            {
              name: "jobDescriptionAndAchievements",
              label: "Job Description and Key Achievements",
              required: true,
              multiline: true,
              rows: 6,
              fullWidth: true,
              maxLength: 4000,
            },
          ],
        },
        {
          section: "Additional Information",
          fields: [
            {
              name: "supervisorName",
              label: "Supervisor Name",
            },
            {
              name: "supervisorContact",
              label: "Supervisor Contact",
            },
            {
              name: "reasonForLeaving",
              label: "Reason for Leaving",
              multiline: true,
              rows: 2,
              fullWidth: true,
            },
            {
              name: "skillsUtilized",
              label: "Skills Utilized",
              fullWidth: true,
            },
            {
              name: "technologiesUsed",
              label: "Technologies Used",
              fullWidth: true,
            },
          ],
        },
      ],
      [isCurrentlyEmployed]
    );

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader title={`Work Experience ${index + 1}`} />
        {formFields.map(({ section, fields }) => (
          <React.Fragment key={section}>
            <SubsectionHeader title={section} className={classes.subsection} />
            <Grid container spacing={3} className={classes.formSection}>
              {fields.map(
                (field) =>
                  !field.hidden && (
                    <Grid
                      item
                      xs={12}
                      sm={field.fullWidth ? 12 : 6}
                      key={field.name}
                    >
                      <FormField
                        name={field.name}
                        label={field.label}
                        required={field.required}
                        value={experience[field.name]}
                        onChange={onFieldChange}
                        error={errors[field.name]}
                        tooltip={`Enter the ${field.label.toLowerCase()}`}
                        icon={field.icon ? <field.icon /> : null}
                        fullWidth
                        multiline={field.multiline}
                        rows={field.rows}
                        type={field.type}
                        maxLength={field.maxLength}
                        aria-label={field.label}
                      >
                        {field.options &&
                          field.options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </FormField>
                      {field.type === "date" && (
                        <DateHelperText text={field.helperText} />
                      )}
                    </Grid>
                  )
              )}
            </Grid>
            {section === "Job Details" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCurrentlyEmployed}
                    onChange={(e) =>
                      onFieldChange("currentlyEmployed", e.target.checked)
                    }
                    name="currentlyEmployed"
                    color="primary"
                  />
                }
                label="I am currently employed in this position"
                className={classes.currentlyEmployedCheckbox}
              />
            )}
          </React.Fragment>
        ))}
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this work experience">
            <IconButton
              onClick={onRemoveEntry}
              color="secondary"
              aria-label="Remove work experience"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another work experience">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add work experience"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

export const WorkExperience = React.memo(() => {
  const classes = useStyles();
  const {
    formData,
    errors,
    updateFormData,
    setWorkExperience,
    toggleNoWorkExperience,
    validateFieldAndUpdateErrors,
  } = useContext(FormContext);

  useEffect(() => {
    const storedData = localStorage.getItem("workExperience");
    if (
      storedData &&
      (!formData.workExperience || formData.workExperience.length === 0)
    ) {
      setWorkExperience(JSON.parse(storedData));
    }
  }, [setWorkExperience, formData.workExperience]);

  const handleChange = useCallback(
    (index, name, value) => {
      updateFormData("workExperience", (prev) => {
        const updated = Array.isArray(prev) ? [...prev] : [];
        updated[index] = { ...updated[index], [name]: value };
        localStorage.setItem("workExperience", JSON.stringify(updated));
        return updated;
      });
      validateFieldAndUpdateErrors(`workExperience[${index}]`, name, value);
    },
    [updateFormData, validateFieldAndUpdateErrors]
  );

  const handleAddEntry = useCallback(() => {
    updateFormData("workExperience", (prev) => {
      const updated = Array.isArray(prev)
        ? [...prev, initialWorkExperience]
        : [initialWorkExperience];
      localStorage.setItem("workExperience", JSON.stringify(updated));
      return updated;
    });
  }, [updateFormData]);

  const handleRemoveEntry = useCallback(
    (index) => {
      updateFormData("workExperience", (prev) => {
        if (prev.length === 1) {
          toggleNoWorkExperience(true);
          return [];
        }
        const updated = prev.filter((_, i) => i !== index);
        localStorage.setItem("workExperience", JSON.stringify(updated));
        return updated;
      });
    },
    [updateFormData, toggleNoWorkExperience]
  );

  const handleNoExperienceChange = useCallback(
    (event) => {
      const noExperience = event.target.checked;
      toggleNoWorkExperience(noExperience);
      if (noExperience) {
        updateFormData("workExperience", []);
      } else if (formData.workExperience.length === 0) {
        updateFormData("workExperience", [initialWorkExperience]);
      }
    },
    [toggleNoWorkExperience, updateFormData, formData.workExperience]
  );

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <div className={classes.formContent}>
        <SectionHeader title="Work Experience" />
        <div className={classes.formInnerContent}>
          <div className={classes.workExperienceEntries}>
            {!formData.noWorkExperience &&
              formData.workExperience.map((experience, index) => (
                <WorkExperienceEntry
                  key={index}
                  index={index}
                  experience={experience}
                  handleChange={handleChange}
                  handleRemoveEntry={handleRemoveEntry}
                  handleAddEntry={handleAddEntry}
                  errors={errors[`workExperience[${index}]`] || {}}
                />
              ))}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.noWorkExperience}
                onChange={handleNoExperienceChange}
                name="noWorkExperience"
                color="primary"
              />
            }
            label="I have no work experience"
            className={classes.noExperienceCheckbox}
          />
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={!!errors.submit}
        autoHideDuration={6000}
        onClose={() => updateFormData("errors", { submit: null })}
      >
        <Alert
          onClose={() => updateFormData("errors", { submit: null })}
          severity="error"
        >
          {errors.submit}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default WorkExperience;
