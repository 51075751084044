import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Tooltip,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TemplateIcon from "@material-ui/icons/Description";
import ApplicationMaterialSkeleton from "./ApplicationMaterialSkeleton";
import { formatResume, formatCoverLetter } from "../util/formatters";
import { FormContext } from "./FormContext";
import debounce from "lodash/debounce";
import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(6),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  contentField: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      padding: theme.spacing(1),
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  jobHeader: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  sectionTitle: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  boldText: {
    fontWeight: "bold",
  },
  workExperienceItem: {
    marginBottom: theme.spacing(2),
  },
  workExperienceDetails: {
    marginBottom: theme.spacing(1),
  },
  additionalQualification: {
    marginBottom: theme.spacing(2),
  },
  templateButton: {
    marginLeft: theme.spacing(1),
  },
  templatePreview: {
    width: "100%",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    border: "2px solid transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  selectedTemplate: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  templateName: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  downloadText: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
  markdownOutput: {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginBottom: theme.spacing(1),
    },
    "& ul, & ol": {
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    "& pre": {
      backgroundColor: "#f0f0f0",
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      overflowX: "auto",
    },
  },
}));

const GeneratedContentDisplay = ({
  generatedContent,
  updateGeneratedContent,
  jobDetails = {},
  isLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { formData } = useContext(FormContext);
  const [expandedPanel, setExpandedPanel] = useState("generate_resume");
  const [textFieldHeights, setTextFieldHeights] = useState({});
  const [coverLetterContent, setCoverLetterContent] = useState("");
  const contentRef = useRef(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const calculateTextFieldHeight = (text) => {
    if (typeof text !== "string") {
      return 100;
    }
    const lineHeight = 20;
    const padding = 20;
    const lineBreaks = (text.match(/\n/g) || []).length;
    const lines = Math.ceil(text.length / 400) + lineBreaks;
    return Math.max(lines * lineHeight + padding, 100);
  };

  useEffect(() => {
    const heights = {};
    Object.entries(generatedContent || {}).forEach(([key, value]) => {
      if (value) {
        heights[key] = calculateTextFieldHeight(
          typeof value === "string" ? value : JSON.stringify(value, null, 2)
        );
      }
    });
    setTextFieldHeights(heights);
  }, [generatedContent, expandedPanel]);

  useEffect(() => {
    if (generatedContent?.generate_cover_letter) {
      const content = generatedContent.generate_cover_letter;
      setCoverLetterContent(
        `${content.greeting}\n\n${
          content.openingParagraph
        }\n\n${content.bodyParagraphs.join("\n\n")}\n\n${
          content.closingParagraph
        }\n\n${content.signature}`
      );
    }
  }, [generatedContent]);

  const formatBulletPoints = (responsibilities) => {
    return responsibilities.map((resp) => `• ${resp}`).join("\n");
  };

  const parseBulletPoints = (text) => {
    return text.split("\n").map((line) => line.trim().replace(/^•\s*/, ""));
  };

  const formatDate = (dateString) => {
    if (dateString === "9999-01-01") return "Current";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatFullResume = (content) => {
    if (!content || typeof content !== "object") return "";

    let formattedResume = "";

    formattedResume += `${content.personalInformation.firstName} ${content.personalInformation.lastName}\n`;
    formattedResume += `${content.personalInformation.email} | ${content.personalInformation.phone}\n`;
    if (content.personalInformation.address) {
      formattedResume += `${content.personalInformation.address}\n`;
    }
    if (content.personalInformation.linkedinUrl) {
      formattedResume += `${content.personalInformation.linkedinUrl}\n`;
    }
    formattedResume += "\n";

    formattedResume += "Professional Summary\n";
    formattedResume += `${content.personalInformation.professionalSummary}\n\n`;

    formattedResume += "Work Experience\n";
    content.workExperience.forEach((job) => {
      formattedResume += `${job.jobTitle} at ${job.companyName}\n`;
      formattedResume += `${formatDate(job.startDate)} - ${formatDate(
        job.endDate
      )}\n`;
      job.responsibilities.forEach((resp) => {
        formattedResume += `• ${resp}\n`;
      });
      formattedResume += "\n";
    });

    formattedResume += "Education\n";
    content.education.forEach((edu) => {
      formattedResume += `${edu.degree} from ${edu.institution}, ${formatDate(
        edu.graduationDate
      )}\n`;
    });
    formattedResume += "\n";

    formattedResume += "Skills\n";
    formattedResume += content.skills.join(", ");
    formattedResume += "\n\n";

    if (
      content.additionalQualifications &&
      content.additionalQualifications.length > 0
    ) {
      formattedResume += "Additional Qualifications\n";
      content.additionalQualifications.forEach((qual) => {
        formattedResume += `${qual.qualificationType}: ${qual.details}\n\n`;
      });
    }

    return formattedResume;
  };

  const formatFullCoverLetter = (content) => {
    if (!content || typeof content !== "object") return "";

    let formattedCoverLetter = "";

    if (content.recipientName) {
      formattedCoverLetter += `${content.recipientName}\n`;
    }
    if (content.recipientTitle) {
      formattedCoverLetter += `${content.recipientTitle}\n`;
    }
    if (content.companyName) {
      formattedCoverLetter += `${content.companyName}\n\n`;
    }

    formattedCoverLetter += `${content.greeting || ""}\n\n`;
    formattedCoverLetter += `${content.openingParagraph || ""}\n\n`;
    formattedCoverLetter += `${(content.bodyParagraphs || []).join(
      "\n\n"
    )}\n\n`;
    formattedCoverLetter += `${content.closingParagraph || ""}\n\n`;
    formattedCoverLetter += `${content.signature || ""}`;

    return formattedCoverLetter;
  };

  const handleCopy = (content, key) => {
    let textToCopy;

    if (key === "generate_resume") {
      textToCopy = formatFullResume(content);
    } else if (key === "generate_cover_letter") {
      textToCopy = formatFullCoverLetter(content);
    } else {
      // For markdown content, we'll strip out the markdown syntax
      textToCopy =
        typeof content === "string"
          ? content.replace(/[#*_`]|\n```[\s\S]*?\n```/g, "") // This removes common markdown syntax including code blocks
          : JSON.stringify(content, null, 2);
    }

    navigator.clipboard.writeText(textToCopy).then(() => {
      console.log("Text copied to clipboard");
    });
  };

  const renderResumeSection = (content) => {
    if (!content || typeof content !== "object") {
      return <Typography>Invalid resume data</Typography>;
    }

    return (
      <Box>
        <Typography variant="h6" className={classes.sectionTitle}>
          Contact Information
        </Typography>
        <Typography>{`${content.personalInformation.firstName} ${content.personalInformation.lastName}`}</Typography>
        <Typography>{`${content.personalInformation.email} | ${content.personalInformation.phone}`}</Typography>
        {content.personalInformation.address && (
          <Typography>{content.personalInformation.address}</Typography>
        )}
        {content.personalInformation.linkedinUrl && (
          <Typography>{content.personalInformation.linkedinUrl}</Typography>
        )}

        <Typography variant="h6" className={classes.sectionTitle}>
          Professional Summary
        </Typography>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={content.personalInformation.professionalSummary || ""}
          onChange={(e) => {
            const updatedResume = { ...content };
            updatedResume.personalInformation.professionalSummary =
              e.target.value;
            updateGeneratedContent("generate_resume", updatedResume);
          }}
          className={classes.contentField}
        />

        <Typography variant="h6" className={classes.sectionTitle}>
          Work Experience
        </Typography>
        {content.workExperience.map((job, index) => (
          <Box key={index} className={classes.workExperienceItem}>
            <Box className={classes.workExperienceDetails}>
              <Typography
                className={classes.boldText}
              >{`${job.jobTitle} at ${job.companyName}`}</Typography>
              <Typography className={classes.boldText}>
                {`${formatDate(job.startDate)} - ${formatDate(job.endDate)}`}
              </Typography>
            </Box>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              label="Responsibilities"
              value={formatBulletPoints(job.responsibilities)}
              onChange={(e) => {
                const updatedResume = { ...content };
                updatedResume.workExperience[index].responsibilities =
                  parseBulletPoints(e.target.value);
                updateGeneratedContent("generate_resume", updatedResume);
              }}
              className={classes.contentField}
            />
          </Box>
        ))}

        <Typography variant="h6" className={classes.sectionTitle}>
          Education
        </Typography>
        {content.education.map((edu, index) => (
          <Typography key={index}>{`${edu.degree} from ${
            edu.institution
          }, ${formatDate(edu.graduationDate)}`}</Typography>
        ))}

        <Typography variant="h6" className={classes.sectionTitle}>
          Skills
        </Typography>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={(content.skills || []).join(", ")}
          onChange={(e) => {
            const updatedResume = { ...content };
            updatedResume.skills = e.target.value
              .split(", ")
              .map((skill) => skill.trim());
            updateGeneratedContent("generate_resume", updatedResume);
          }}
          className={classes.contentField}
        />
      </Box>
    );
  };

  const renderCoverLetterSection = (content) => {
    if (!content || typeof content !== "object") {
      return <Typography>Invalid cover letter data</Typography>;
    }

    const today = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const headerInfo = [
      `${formData.personalInformation.firstName} ${formData.personalInformation.lastName}`,
      formData.personalInformation.streetAddress,
      formData.personalInformation.city &&
      formData.personalInformation.state &&
      formData.personalInformation.zipCode
        ? `${formData.personalInformation.city}, ${formData.personalInformation.state} ${formData.personalInformation.zipCode}`
        : null,
      formData.personalInformation.phone,
      formData.personalInformation.email,
      formData.personalInformation.linkedinUrl,
      "",
      today,
      "",
      "",
      "",
      content.recipientName || "Hiring Manager",
      content.companyName,
      jobDetails.jobLocation,
    ]
      .filter(Boolean)
      .join("\n");

    return (
      <Box>
        <Typography
          variant="body2"
          style={{ whiteSpace: "pre-line", marginBottom: "20px" }}
        >
          {headerInfo}
        </Typography>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={coverLetterContent}
          onChange={handleCoverLetterChange}
          className={classes.contentField}
        />
      </Box>
    );
  };

  const debouncedUpdateCoverLetter = useCallback(
    debounce((newContent) => {
      const updatedCoverLetter = parseCoverLetterContent(newContent);
      updateGeneratedContent("generate_cover_letter", updatedCoverLetter);
    }, 300),
    []
  );

  const handleCoverLetterChange = (e) => {
    const newContent = e.target.value;
    setCoverLetterContent(newContent);
    debouncedUpdateCoverLetter(newContent);
  };

  const parseCoverLetterContent = (text) => {
    const lines = text.split("\n").filter((line) => line.trim() !== "");
    return {
      greeting: lines[0] || "",
      openingParagraph: lines[1] || "",
      bodyParagraphs: lines.slice(2, -2) || [],
      closingParagraph: lines[lines.length - 2] || "",
      signature: lines[lines.length - 1] || "",
    };
  };

  const handleChooseTemplate = (type) => {
    setSelectedTemplateType(type);
    setOpenTemplateDialog(true);
  };

  const handleCloseTemplateDialog = () => {
    setOpenTemplateDialog(false);
  };

  const handleSelectTemplate = (templateId) => {
    setSelectedTemplateId(templateId);
  };

  const handleApplyTemplate = () => {
    console.log("Template ID:", selectedTemplateId);
    console.log(
      "Document Contents:",
      selectedTemplateType === "resume"
        ? formatFullResume(generatedContent.generate_resume)
        : formatFullCoverLetter(generatedContent.generate_cover_letter)
    );
    setOpenTemplateDialog(false);
  };

  const renderTemplateCarousel = () => {
    const templates = [
      { id: "template1", name: "Classic", color: "#f0f0f0" },
      { id: "template2", name: "Modern", color: "#e0e0e0" },
      { id: "template3", name: "Creative", color: "#d0d0d0" },
      { id: "template4", name: "Professional", color: "#c0c0c0" },
      { id: "template5", name: "Elegant", color: "#b0b0b0" },
    ];

    return (
      <Carousel>
        {templates.map((template) => (
          <Box
            key={template.id}
            className={`${classes.templatePreview} ${
              selectedTemplateId === template.id ? classes.selectedTemplate : ""
            }`}
            style={{ backgroundColor: template.color }}
            onClick={() => handleSelectTemplate(template.id)}
          >
            <Typography variant="h6" className={classes.templateName}>
              {template.name}
            </Typography>
            <Typography variant="body1" className={classes.downloadText}>
              Click to download your{" "}
              {selectedTemplateType === "resume" ? "resume" : "cover letter"}{" "}
              with this template
            </Typography>
          </Box>
        ))}
      </Carousel>
    );
  };

  const renderContentSection = (title, content, key) => {
    if (isLoading) {
      return <ApplicationMaterialSkeleton key={key} title={title} />;
    }

    if (!content) return null;

    let displayContent;
    if (key === "generate_resume") {
      displayContent = (
        <>
          {renderResumeSection(content)}
          {/* <Button
            variant="contained"
            color="secondary"
            className={classes.templateButton}
            startIcon={<TemplateIcon />}
            onClick={() => handleChooseTemplate("resume")}
          >
            Choose Resume Template
          </Button> */}
        </>
      );
    } else if (key === "generate_cover_letter") {
      displayContent = (
        <>
          {renderCoverLetterSection(content)}
          <Button
            variant="contained"
            color="secondary"
            className={classes.templateButton}
            startIcon={<TemplateIcon />}
            onClick={() => handleChooseTemplate("cover_letter")}
          >
            Choose Cover Letter Template
          </Button>
        </>
      );
    } else {
      displayContent = (
        <ReactMarkdown
          className={classes.markdownOutput}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  style={atomDark}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {typeof content === "string"
            ? content
            : JSON.stringify(content, null, 2)}
        </ReactMarkdown>
      );
    }

    return (
      <Accordion
        expanded={expandedPanel === key}
        onChange={handleAccordionChange(key)}
        key={key}
        style={{ marginBottom: theme.spacing(2) }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${key}-content`}
          id={`${key}-header`}
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: theme.spacing(1) }}>
          <Box width="100%">
            {displayContent}
            <Box className={classes.buttonGroup}>
              <Tooltip title="Copy to clipboard" aria-label="copy to clipboard">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<FileCopyIcon />}
                  onClick={() => handleCopy(content, key)}
                >
                  Copy
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box ref={contentRef} className={classes.root}>
      <Typography variant="h5" className={classes.jobHeader}>
        {jobDetails.jobTitle} at {jobDetails.companyName}
      </Typography>
      {renderContentSection(
        "Resume",
        generatedContent?.resume, // Update key to match backend response
        "resume" // Update key to match backend response
      )}

      {renderContentSection(
        "Cover Letter",
        generatedContent?.coverLetter, // Update key to match backend response
        "coverLetter" // Update key to match backend response
      )}
      {renderContentSection(
        "References",
        generatedContent?.generate_references,
        "generate_references"
      )}
      {renderContentSection(
        "Recruiter Message",
        generatedContent?.generate_recruiter_message,
        "generate_recruiter_message"
      )}
      {renderContentSection(
        "LinkedIn Profile Optimization",
        generatedContent?.generate_linkedin_profile_optimization,
        "generate_linkedin_profile_optimization"
      )}
      {renderContentSection(
        "Interview Preparation",
        generatedContent?.generate_interview_preparation,
        "generate_interview_preparation"
      )}
      {renderContentSection(
        "Networking Email Templates",
        generatedContent?.generate_networking_email_templates,
        "generate_networking_email_templates"
      )}
      {renderContentSection(
        "Career Development Plan",
        generatedContent?.generate_career_development_plan,
        "generate_career_development_plan"
      )}
      {renderContentSection(
        "Job Application Follow-up Emails",
        generatedContent?.generate_job_application_follow_up_emails,
        "generate_job_application_follow_up_emails"
      )}
      {renderContentSection(
        "Salary Negotiation Scripts",
        generatedContent?.generate_salary_negotiation_scripts,
        "generate_salary_negotiation_scripts"
      )}
      {renderContentSection(
        "Professional Bio",
        generatedContent?.generate_professional_bio,
        "generate_professional_bio"
      )}
      {renderContentSection(
        "Personal Branding Tips",
        generatedContent?.generate_personal_branding_tips,
        "generate_personal_branding_tips"
      )}
      {renderContentSection(
        "Skill Gap Analysis",
        generatedContent?.generate_skill_gap_analysis,
        "generate_skill_gap_analysis"
      )}
      {renderContentSection(
        "Company Research Reports",
        generatedContent?.generate_company_research_reports,
        "generate_company_research_reports"
      )}
      <Dialog
        open={openTemplateDialog}
        onClose={handleCloseTemplateDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Choose a Template</DialogTitle>
        <DialogContent>{renderTemplateCarousel()}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTemplateDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleApplyTemplate}
            color="primary"
            variant="contained"
            disabled={!selectedTemplateId}
          >
            Apply Template
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GeneratedContentDisplay;
