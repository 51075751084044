// The util.js file appears to contain several utility functions that are related to making API requests. The first function, apiRequest, is an async function that takes in a path, a method (defaulting to "GET"), and some data. It first gets the current user's access token from the Firebase auth object, and then sends a fetch request to the specified path, with the specified method and data. The request headers include the "Content-Type" and "Authorization" headers, with the latter being set to a "Bearer" token that includes the user's access token. The function then returns the JSON-parsed response data if the response status is not "error", or throws a custom error if the status is "error".
// The second function, apiRequestExternal, is similar to apiRequest, but it makes a fetch request to an external URL rather than to the app's API. It also only takes in a URL, a method (defaulting to "GET"), and some data.
// The CustomError function is a simple utility that creates a new error object with a specified code and message.
// The usePrevious hook is a custom hook that returns the previous value of a given state. It does this by using the useRef hook to create a mutable ref object and setting its current value to the state in a useEffect hook. The ref's current value is then returned as the previous value of the state.
import { useRef, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { firebaseApp } from "./firebase";

const auth = getAuth(firebaseApp);
// Make an API request to `/api/{path}`
export async function apiRequest(path, method = "GET", data) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          signOut(auth);
        }
        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

export function currentDate() {
  const now = new Date();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const year = now.getFullYear();
  return `${month}/${day}/${year}`;
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}
