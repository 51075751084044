// validation.js

const validateEmailOrPhone = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?[\d\s-]{10,14}$/;
  return emailRegex.test(value) || phoneRegex.test(value);
};

export const validateGPA = (value) => {
  if (isNaN(value)) {
    return "GPA must be a number";
  }
  if (value < 0 || value > 4) {
    return "GPA must be between 0 and 4";
  }
  return "";
};

export const validateDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let startError = "";
  let endError = "";

  if (endDate && start >= end) {
    startError = "Start Date must be before End Date";
  }
  if (startDate && end <= start) {
    endError = "End Date must be after Start Date";
  }

  return { startDate: startError, endDate: endError };
};

export const validateField = (name, value, formData) => {
  switch (name) {
    case "firstName":
    case "lastName":
    case "streetAddress":
    case "city":
    case "state":
    case "zipCode":
    case "country":
      return value.trim() === ""
        ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
        : "";
    case "email":
      return !value
        ? "Email is required"
        : !/\S+@\S+\.\S+/.test(value)
        ? "Invalid email format"
        : "";
    case "phone":
      return !value
        ? "Phone number is required"
        : !/^\+?[1-9]\d{1,14}$/.test(value)
        ? "Invalid phone number format"
        : "";
    case "linkedinUrl":
      return value &&
        !/^https:\/\/[a-z]{2,3}\.linkedin\.com\/in\/[\w\-]+\/?$/.test(value)
        ? "Invalid LinkedIn URL"
        : "";
    case "websiteUrl":
      return value &&
        !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
          value
        )
        ? "Invalid website URL"
        : "";
    case "gpa":
      return validateGPA(value);
    case "location":
      return !value.trim()
        ? "Location is required"
        : !/^[A-Za-z\s]+,\s[A-Za-z\s]+$/.test(value)
        ? "Invalid location format. Use 'City, State'"
        : "";
    case "startDate":
    case "endDate":
      return !value
        ? `${name === "startDate" ? "Start" : "End"} date is required`
        : new Date(value) > new Date()
        ? `${
            name === "startDate" ? "Start" : "End"
          } date cannot be in the future`
        : "";
    case "jobDescription":
      return !value.trim()
        ? "Job description is required"
        : value.length < 50
        ? "Job description must be at least 50 characters"
        : value.length > 2000
        ? "Job description must be 2000 characters or less"
        : "";
    case "keyAchievements":
    case "reasonForLeaving":
    case "skillsUtilized":
    case "technologiesUsed":
      return value.length > 1000
        ? `${
            name.charAt(0).toUpperCase() + name.slice(1)
          } must be 1000 characters or less`
        : "";
    case "supervisorName":
      return value.length > 100
        ? "Supervisor name must be 100 characters or less"
        : "";
    case "supervisorContact":
      return value && !validateEmailOrPhone(value)
        ? "Invalid email or phone number format"
        : "";
    case "jobTitle":
    case "companyName":
      return value.trim() === ""
        ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
        : value.length > 100
        ? `${
            name.charAt(0).toUpperCase() + name.slice(1)
          } must be 100 characters or less`
        : "";
    case "jobLocation":
      return value.length > 100
        ? "Job location must be 100 characters or less"
        : "";
    case "jobType":
      return !value ? "Job type is required" : "";
    case "salary":
      return value && isNaN(value) ? "Salary must be a number" : "";
    case "applicationDeadline":
      return value && new Date(value) < new Date()
        ? "Application deadline cannot be in the past"
        : "";
    // Additional Qualifications validation
    case "qualificationType":
      return !value ? "Qualification type is required" : "";
    case "militaryBranch":
    case "militaryRank":
      return !value
        ? `${name === "militaryBranch" ? "Branch" : "Rank"} is required`
        : "";
    case "militaryStartDate":
    case "militaryEndDate":
      return !value
        ? `${name === "militaryStartDate" ? "Start" : "End"} date is required`
        : new Date(value) > new Date()
        ? `${
            name === "militaryStartDate" ? "Start" : "End"
          } date cannot be in the future`
        : "";
    case "workAuthorizationStatus":
      return !value ? "Work authorization status is required" : "";
    case "securityClearanceLevel":
      return !value ? "Security clearance level is required" : "";
    case "disabilityDetails":
      return value.length > 500
        ? "Disability details must be 500 characters or less"
        : "";
    case "licenseName":
    case "licenseIssuer":
      return !value
        ? `${
            name === "licenseName" ? "License name" : "Issuing organization"
          } is required`
        : "";
    case "licenseDate":
    case "licenseExpiration":
      return !value
        ? `${
            name === "licenseDate" ? "Date obtained" : "Expiration date"
          } is required`
        : new Date(value) > new Date()
        ? `${
            name === "licenseDate" ? "Date obtained" : "Expiration date"
          } cannot be in the future`
        : "";
    case "patentTitle":
    case "patentNumber":
      return !value
        ? `${
            name === "patentTitle" ? "Patent title" : "Patent number"
          } is required`
        : "";
    case "patentDate":
      return !value
        ? "Patent date is required"
        : new Date(value) > new Date()
        ? "Patent date cannot be in the future"
        : "";
    case "researchDescription":
      return !value
        ? "Research description is required"
        : value.length > 1000
        ? "Research description must be 1000 characters or less"
        : "";
    case "communityServiceOrg":
    case "communityServiceRole":
      return !value
        ? `${
            name === "communityServiceOrg" ? "Organization" : "Role"
          } is required`
        : "";
    case "communityServiceStartDate":
    case "communityServiceEndDate":
      return !value
        ? `${
            name === "communityServiceStartDate" ? "Start" : "End"
          } date is required`
        : new Date(value) > new Date()
        ? `${
            name === "communityServiceStartDate" ? "Start" : "End"
          } date cannot be in the future`
        : "";
    case "professionalDevelopmentName":
      return !value ? "Workshop/Seminar name is required" : "";
    case "professionalDevelopmentDate":
      return !value
        ? "Date is required"
        : new Date(value) > new Date()
        ? "Date cannot be in the future"
        : "";
    default:
      return "";
  }
};

export const validatePersonalInfo = (formData) => {
  const errors = {};
  for (const [key, value] of Object.entries(formData)) {
    const error = validateField(key, value, formData);
    if (error) {
      errors[key] = error;
    }
  }
  return errors;
};

export const validateWorkExperience = (workExperience, formData) => {
  const errors = {};

  workExperience.forEach((experience, index) => {
    const entryErrors = {};

    for (const [key, value] of Object.entries(experience)) {
      const error = validateField(key, value, formData);
      if (error) {
        entryErrors[key] = error;
      }
    }

    if (
      experience.startDate &&
      experience.endDate &&
      !experience.currentlyEmployed
    ) {
      const dateErrors = validateDates(
        experience.startDate,
        experience.endDate
      );
      if (dateErrors.startDate) entryErrors.startDate = dateErrors.startDate;
      if (dateErrors.endDate) entryErrors.endDate = dateErrors.endDate;
    }

    if (Object.keys(entryErrors).length > 0) {
      errors[index] = entryErrors;
    }
  });

  return errors;
};

export const validateEducation = (education) => {
  const errors = {};

  education.forEach((entry, index) => {
    const entryErrors = {};

    if (entry.educationType !== "NA") {
      if (!entry.institutionName.trim()) {
        entryErrors.institutionName = "Institution Name is required";
      }
      if (!entry.country.trim()) {
        entryErrors.country = "Country is required";
      }
      if (
        entry.startDate &&
        entry.endDate &&
        new Date(entry.startDate) > new Date(entry.endDate)
      ) {
        entryErrors.startDate = "Start Date must be before End Date";
        entryErrors.endDate = "End Date must be after Start Date";
      }
    }

    if (entry.educationType === "College") {
      if (!entry.degree.trim()) {
        entryErrors.degree = "Degree is required";
      }
      if (!entry.fieldOfStudy.trim()) {
        entryErrors.fieldOfStudy = "Field of Study is required";
      }
      if (entry.gpa && (isNaN(entry.gpa) || entry.gpa < 0 || entry.gpa > 4)) {
        entryErrors.gpa = "GPA must be a number between 0 and 4";
      }
    }

    if (entry.educationType === "Other" && !entry.educationDescription.trim()) {
      entryErrors.educationDescription = "Education Description is required";
    }

    if (Object.keys(entryErrors).length > 0) {
      errors[index] = entryErrors;
    }
  });

  return errors;
};

export const validateTargetJob = (targetJob, formData) => {
  const errors = {};

  for (const [key, value] of Object.entries(targetJob)) {
    if (key !== "generationOptions") {
      const error = validateField(key, value, formData);
      if (error) {
        errors[key] = error;
      }
    }
  }

  // Validate that at least one generation option is selected
  if (
    targetJob.generationOptions &&
    !Object.values(targetJob.generationOptions).some(Boolean)
  ) {
    errors.generationOptions =
      "At least one job application toolkit option must be selected";
  }

  return errors;
};

export const validateAdditionalQualifications = (additionalQualifications) => {
  const errors = {};

  additionalQualifications.forEach((qualification, index) => {
    const entryErrors = {};

    for (const [key, value] of Object.entries(qualification)) {
      const error = validateField(key, value);
      if (error) {
        entryErrors[key] = error;
      }
    }

    if (Object.keys(entryErrors).length > 0) {
      errors[index] = entryErrors;
    }
  });

  return errors;
};

export const validateForm = (formData) => {
  const errors = {};

  const personalInfoErrors = validatePersonalInfo(formData.personalInformation);
  if (Object.keys(personalInfoErrors).length > 0) {
    errors.personalInformation = personalInfoErrors;
  }

  if (formData.workExperience) {
    const workExperienceErrors = validateWorkExperience(
      formData.workExperience,
      formData
    );
    if (Object.keys(workExperienceErrors).length > 0) {
      errors.workExperience = workExperienceErrors;
    }
  }

  if (formData.education) {
    const educationErrors = validateEducation(formData.education);
    if (Object.keys(educationErrors).length > 0) {
      errors.education = educationErrors;
    }
  }

  if (formData.targetJobInformation) {
    const targetJobErrors = formData.targetJobInformation.map((job) =>
      validateTargetJob(job, formData)
    );
    if (
      targetJobErrors.some((jobErrors) => Object.keys(jobErrors).length > 0)
    ) {
      errors.targetJobInformation = targetJobErrors;
    }
  }

  if (formData.additionalQualifications) {
    const additionalQualificationsErrors = validateAdditionalQualifications(
      formData.additionalQualifications
    );
    if (Object.keys(additionalQualificationsErrors).length > 0) {
      errors.additionalQualifications = additionalQualificationsErrors;
    }
  }

  return errors;
};
