// In this file, a LegalSection component is being rendered with some props that are being passed to it. 
// The section prop is being set to the value of the section query parameter in the URL, 
// and the key prop is being set to the same value.

// The LegalPage component is being rendered when the user navigates to a URL that matches the route for this page. 
// The useRouter hook is being used to get access to the router object, 
// which allows the component to access the URL's query parameters. 
// In this case, the section query parameter is being passed to the LegalSection component as a prop, 
// which will be used to determine which legal content to display.

// The key prop is being set to the same value as the section prop, but it is not clear what purpose this serves. 
// It is possible that this prop is being used as a unique identifier for the LegalSection component, 
// but without more context it is not possible to determine its exact purpose.

import React from "react";
import Meta from "./../components/Meta";
import LegalSection from "./../components/LegalSection";
import { useRouter } from "./../util/router";

function LegalPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Legal" />
      <LegalSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default LegalPage;
