// The newsletter.js file exports an object with a single function, subscribe, which makes an API request to the /api/newsletter endpoint with the specified data. This function is likely used to subscribe a user to a newsletter or email list.

// The subscribe function makes an HTTP POST request to the /api/newsletter endpoint with the specified data in the request body, and returns a promise that resolves to the server's response. The response is expected to be in JSON format and will be parsed before the promise is resolved.

// The newsletter object is then exported and can be imported and used in other parts of the application.

import { apiRequest } from "./util";

function subscribe(data) {
  return apiRequest("newsletter", "POST", data);
}

const newsletter = { subscribe };

export default newsletter;
