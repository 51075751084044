import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms of Service</h1>
      <p>Last Updated: 2/5/2022</p>
      <p>
        Agreement To Terms These Terms of Use (“Terms”) constitute a legally
        binding agreement made between you, whether personally or on behalf of
        an entity (“you”) and Resume Revival, Inc. and its subsidiaries and
        affiliates (collectively, “Company“, “we”, “us”, or “our”), concerning
        your access to and use of the resumerevival.xyz platform as well as any
        other website, media channel, mobile site or mobile application related,
        linked, or otherwise connected thereto (collectively, the “Platform”).
      </p>
      <p>
        We operate globally and, as a result, different laws may apply depending
        on where a user resides. These Terms are intended to apply to all users
        regardless of geographic location to the extent legally enforceable in
        each user’s place of residence.
      </p>
      <p>
        * if you are a resident of the European Union, please see the EU
        Residents – Supplemental Terms of Use for more information about
        specific additional or alternative terms that apply to you. * If you are
        a resident of the United States, please see the US Residents –
        Supplemental Terms of Use for more information about specific additional
        or alternative terms that apply to you.
      </p>
      <p>
        You agree that by accessing the Platform, you have read, understood, and
        agree to be bound by all of these Terms including any supplemental terms
        that apply to you based on where you live. IF YOU DO NOT AGREE WITH ALL
        OF THESE TERMS (INCLUDING ANY SUPPLEMENTAL TERMS), THEN YOU ARE
        EXPRESSLY PROHIBITED FROM USING THE PLATFORM AND YOU MUST DISCONTINUE
        USE IMMEDIATELY.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Platform from time to time are hereby expressly incorporated by
        reference into these Terms. We reserve the right, in our sole
        discretion, to make changes or modifications to these Terms at any time
        and for any reason. We will alert you about any changes by updating the
        “Last Updated” date of these Terms, and you waive any right to receive
        specific notice of each such change. It is your responsibility to
        periodically review these Terms to stay informed of updates. You will be
        subject to, and will be deemed to have been made aware of and to have
        accepted, the changes in any revised Terms by your continued use of the
        Platform after the date such revised Terms are posted.
      </p>
      <p>
        The Platform is intended for users who are at least 18 years of age.
      </p>
      <p>
        NOTE: THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION AND
        ARBITRATION PROVISION, INCLUDING A CLASS ACTION WAIVER THAT MAY AFFECT
        YOUR RIGHTS UNDER THESE TERMS AND CONDITIONS AND WITH RESPECT TO ANY AND
        ALL DISPUTES YOU MAY HAVE WITH THE COMPANY.
      </p>
      <h2>Intellectual Property Rights</h2>
      <p>
        Unless otherwise indicated, the Platform is our proprietary property and
        all source code, databases, functionality, software, website designs,
        audio, video, text, photographs, and graphics on the Platform
        (collectively, the “Content”) and the trademarks, service marks, and
        logos contained therein (the “Marks”) are owned by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of the United
        States, the European Union, other foreign jurisdictions, and
        international conventions. The Content and the Marks are provided on the
        Platform “AS IS” for your information and personal use only. Except as
        expressly provided in these Terms, no part of the Platform and no
        Content or Marks may be copied, reproduced, aggregated, republished,
        uploaded, posted, publicly displayed, encoded, translated, transmitted,
        distributed, sold, licensed, or otherwise exploited for any commercial
        purpose whatsoever, without our express prior written permission.
      </p>
      <p>
        Provided that you are eligible to use the Platform, you are granted a
        limited license to access and use the Platform and to download or print
        a copy of any portion of the Content to which you have properly gained
        access solely for your personal, non-commercial use. We reserve all
        rights not expressly granted to you in and to the Platform, the Content
        and the Marks.
      </p>
      <h2>User Representations</h2>
      <p>
        By using the Platform, you represent and warrant that: (1) all
        registration information and other information you submit will be true,
        accurate, current, and complete; (2) you will maintain the accuracy of
        such information and promptly update such registration information as
        necessary; (3) you have the legal capacity and you agree to comply with
        these Terms; (4) you are not under the age of 18; (5) you are not a
        minor in the jurisdiction in which you reside; (6) you will not access
        the Platform through automated or non-human means, whether through a
        bot, script or otherwise; (7) you will not use the Platform for any
        illegal or unauthorized purpose; and (8) your use of the Platform will
        not violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Platform (or any
        portion thereof).
      </p>
      <p>
        The information provided on the Platform is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Platform from other locations do so on their own
        initiative and are solely responsible for compliance with local laws to
        the extent local laws are applicable.
      </p>
      <h2>User Registration</h2>
      <p>
        You may be required to register with the Platform or one of its
        component sites. You agree to keep your password confidential and will
        be responsible for all use of your account and password. We reserve the
        right to remove, reclaim, or change a username you select if we
        determine, in our sole discretion, that such username is inappropriate,
        obscene, or otherwise objectionable.
      </p>
      <h2>Fees And Payment</h2>
      <p>
        We accept various methods of payment including debit/credit cards and
        third-party providers such as PayPal. We may offer other payment options
        in certain locations. You may be required to pay a fee to access some of
        our services. You agree to provide current, complete, and accurate
        purchase and account information for all purchases made via the
        Platform. You further agree to promptly update account and payment
        information, including email address, payment method, and payment card
        expiration date, so that we can complete your transactions and contact
        you as needed. We bill you through an online billing service for
        purchases made via the Platform. Sales, use or similar taxes will be
        added to the price of purchases where we determine it is applicable –
        the total price including applicable taxes will be shown at check-out
        before you complete your purchase. We may change our prices at any time.
        All payments shall be in the currency indicated on the payment page.
      </p>
      <p>
        You agree to pay all charges or fees at the prices then in effect for
        your purchases, and you authorize us to charge your chosen payment
        provider for any such amounts upon making your purchase. If your
        purchase is subject to recurring charges, then you consent to our
        charging your payment method on a recurring basis without requiring your
        prior approval for each recurring charge, until you notify us of your
        cancellation. For certain products or services, we offer a payment plan
        option that allows you to receive the product or service upfront, in
        exchange for future payments. Failure to make future payments as agreed
        may result in reporting of your information to credit bureaus or similar
        credit reporting entities and appearing on your credit report.
      </p>
      <p>
        You agree to pay all charges or fees at the prices then in effect for
        your purchases, and you authorize us to charge your chosen payment
        provider for any such amounts upon making your purchase. If your
        purchase is subject to recurring charges, then you consent to our
        charging your payment method on a recurring basis without requiring your
        prior approval for each recurring charge, until you notify us of your
        cancellation.
      </p>
      <p>
        We reserve the right to correct any errors or mistakes in pricing, even
        if we have already requested or received payment. We also reserve the
        right to refuse any order placed through the Platform.
      </p>
      <h2>Cancellation, Refunds, And Guarantees</h2>
      <p>
        If you purchase a subscription to one or more of our services, you can
        cancel your subscription at any time by logging into your account or
        contacting us using the contact information provided below. Your
        cancellation will take effect at the end of the current paid term. No
        refunds will be provided for our subscription services.
      </p>
      <p>
        In general, and except as specifically noted on a specific site, all
        purchases of our professional resume/cover letter-writing services are
        final and no refunds will be provided.
      </p>
      <p>
        If you are dissatisfied with any of our services, please contact the
        support team for the specific brand or email info@kylanthomson.dev, and
        we will provide appropriate assistance.
      </p>
      <h2>Prohibited Activities</h2>
      <p>
        You may not access or use the Platform for any purpose other than that
        for which we make the Platform available. The Platform may not be used
        in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.
      </p>
      <p>As a user of the Platform, you agree not to:</p>
      <ul>
        <li>
          Distribute log in details so that multiple users are sharing one log
          in.
        </li>
        <li>Sell or otherwise transfer your profile.</li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the
          Platform.
        </li>
        <li>
          Use the Platform to advertise or offer to sell goods or services.
        </li>
        <li>
          Use the Platform as part of any effort to compete with us or otherwise
          use the Platform and/or the Content for any revenue-generating
          endeavor or commercial enterprise.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>
          Trick, defraud, or mislead us or other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li>Engage in unauthorized framing of or linking to the Platform.</li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Use any information obtained from the Platform in order to harass,
          abuse, intimidate, threaten or harm another person.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Platform, including features that: (a) prevent or
          restrict the use or copying of any Content; (b) enforce limitations on
          the use of the Platform and/or the Content contained therein; or (c)
          prevent or restrict access to any portion of the Platform.
        </li>
        <li>
          Copy, adapt, decipher, decompile, disassemble, or reverse engineer any
          of the software comprising or in any way making up a part of the
          Platform.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Systematically retrieve data or other content from the Platform to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Make any unauthorized use of the Platform, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Platform or
          the networks or services connected to the Platform.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Platform or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the
          Platform.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as “spyware” or “passive
          collection mechanisms” or “pcms”).
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Platform, or
          using or launching any unauthorized script or other software.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Platform.
        </li>
        <li>
          Use the Platform in a manner inconsistent with any applicable laws or
          regulations.
        </li>
      </ul>
      <h2>User Generated Contributions</h2>
      <p>
        The Platform may invite you to chat, contribute to, or participate in
        blogs, message boards, online forums, and other functionality, and may
        provide you with the opportunity to create, submit, post, display,
        transmit, perform, publish, distribute, or broadcast content and
        materials to us or on the Platform, including but not limited to text,
        writings, resumes, video, audio, photographs, graphics, comments,
        suggestions, or other material but excluding personal data
        (collectively, “Contributions”). Contributions may be viewable by other
        users of the Platform and through third-party websites if you post them
        on certain portions of the Platform (for example, user reviews). As
        such, any Contributions you post may be treated as non-confidential and
        non-proprietary. When you submit, create or make available any
        Contributions, you thereby represent and warrant that:
      </p>
      <ul>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Platform, and other users of the Platform to use your
          Contributions in any manner contemplated by the Platform and these
          Terms.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Platform and these Terms.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, violent,
          harassing, libelous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your Contributions do not harass, ridicule, mock, disparage,
          intimidate, or abuse anyone.
        </li>
        <li>
          Your Contributions do not advocate the violent overthrow of any
          government or incite, encourage, or threaten physical harm against
          another person.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not contain any material that solicits personal
          information from anyone under the age of 18 or exploits people under
          the age of 18 in a sexual or violent manner.
        </li>
        <li>
          Your Contributions do not violate any national, federal or state law
          concerning child pornography, or otherwise intended to protect the
          health or well-being of minors.
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical or mental disability.
        </li>
        <li>
          Your Contributions do not link to material that violates any provision
          of these Terms or any applicable law or regulation. Any use of the
          Platform in violation of the foregoing violates these Terms and may
          result in, among other things, termination or suspension of your
          rights to use the Platform.
        </li>
      </ul>
      <h2>Contribution License</h2>
      <p>
        By submitting or posting your Contributions to any part of the Platform
        or making Contributions accessible to the Platform, you automatically
        grant, and you represent and warrant that you have the right to grant,
        to us an unrestricted, perpetual, non-exclusive, transferable,
        royalty-free, fully-paid, worldwide right, and license to host, to use,
        to display, and to prepare derivative works of such Contributions for
        the purposes of delivering our services to you, refining our services
        and the Platform, and developing new services, and you grant and
        authorize sublicenses of the foregoing. You waive all moral rights in
        your Contributions, and you warrant that moral rights have not otherwise
        been asserted in your Contributions.
      </p>
      <p>
        We do not assert any ownership right over your Contributions. You retain
        full ownership of all of your Contributions and any intellectual
        property rights or other proprietary rights associated with your
        Contributions. We are not liable for any statements or representations
        in your Contributions provided by you in any area on the Platform. You
        are solely responsible for your Contributions to the Platform and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your Contributions.
      </p>
      <p>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the
        Platform; and (3) to pre-screen or delete any Contributions at any time
        and for any reason, without notice. We have no obligation to monitor
        your Contributions.
      </p>
      <h2>Social Media</h2>
      <p>
        As part of the functionality of the Platform, you may link your account
        with online accounts you have with third-party service providers (each
        such account, a “Third-Party Account”) by either: (1) providing your
        Third-Party Account login information through the Platform; or (2)
        allowing us to access your Third-Party Account, as is permitted under
        the applicable terms and conditions that govern your use of each
        Third-Party Account. You represent and warrant that you are entitled to
        disclose your Third-Party Account login information to us and/or grant
        us access to your Third-Party Account, without breach by you of any of
        the terms and conditions that govern your use of the applicable
        Third-Party Account, and without obligating us to pay any fees or making
        us subject to any usage limitations imposed by the third-party service
        provider of the Third-Party Account. By granting us access to any
        Third-Party Accounts, you understand that: (1) we may access, make
        available, and store (if applicable) any content that you have provided
        to and stored in your Third-Party Account (the “Social Network Content”)
        so that it is available on and through the Platform via your account,
        including without limitation any friend lists; and (2) we may submit to
        and receive from your Third-Party Account additional information to the
        extent you are notified when you link your account with the Third-Party
        Account. Depending on the Third-Party Accounts you choose and subject to
        the privacy settings that you have set in such Third-Party Accounts,
        personal information that you post to your Third-Party Accounts may be
        available on and through your account on the Platform. Please note that
        if a Third-Party Account or associated service becomes unavailable or
        our access to such Third-Party Account is terminated by the third-party
        service provider, then Social Network Content may no longer be available
        on and through the Platform. You will have the ability to disable the
        connection between your account on the Platform and your Third-Party
        Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
        THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS
        IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
        PROVIDERS. We make no effort to review any Social Network Content for
        any purpose, including but not limited to, for accuracy, legality, or
        non-infringement, and we are not responsible for any Social Network
        Content. You acknowledge and agree that we may access your email address
        book associated with a Third-Party Account and your contacts list stored
        on your mobile device or tablet computer solely for purpose of
        identifying and informing you of those contacts who have also registered
        to use the Platform. You can deactivate the connection between the
        Platform and your Third-Party Account by contacting us using the contact
        information below or through your account settings (if applicable). We
        will attempt to delete any information stored on our servers that was
        obtained through such Third-Party Account, except the username and
        profile picture that become associated with your Platform account(s).
      </p>
      <h2>Third-Party Websites And Content</h2>
      <p>
        The Platform may contain (or you may be sent via the Platform) links to
        other websites (“Third-Party Websites”) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties (“Third-Party Content”).
        Such Third-Party Websites and Third-Party Content are not investigated,
        monitored, or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third-Party Websites accessed
        through the Platform or any Third-Party Content posted on, available
        through, or installed from the Platform, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-Party Content does
        not imply approval or endorsement thereof by us. If you decide to leave
        the Platform and access the Third-Party Websites or to use or install
        any Third-Party Content, you do so at your own risk, and you should be
        aware these Terms no longer govern. You should review the applicable
        terms and policies, including privacy and data gathering practices, of
        any website to which you navigate from the Platform or relating to any
        applications you use or install from the Platform. Any purchases you
        make through Third-Party Websites will be through other websites and
        from other companies, and we take no responsibility whatsoever in
        relation to such purchases which are exclusively between you and the
        applicable third party. You agree and acknowledge that we do not endorse
        the products or services offered on Third-Party Websites and you shall
        hold us harmless from any harm caused by your purchase of such products
        or services. Additionally, you shall hold us harmless from any losses
        sustained by you or harm caused to you relating to or resulting in any
        way from any Third-Party Content or any contact with Third-Party
        Websites.
      </p>
      <h2>Platform Management</h2>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the
        Platform for violations of these Terms; (2) take appropriate legal
        action against anyone who, in our sole discretion, violates the law or
        these Terms, including without limitation, reporting such user to law
        enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions; (4) in our sole discretion and without limitation,
        notice, or liability, to remove from the Platform or otherwise disable
        all files and content that are excessive in size or are in any way
        burdensome to our systems; and (5) otherwise manage the Platform in a
        manner designed to protect our rights and property and to facilitate the
        proper functioning of the Platform.
      </p>
      <h2>Privacy Policy</h2>
      <p>
        We care about data privacy and security. Please review our Privacy
        Policy for details about what information we collect, how we use and
        share it, and how it is protected. In addition, we will maintain certain
        data that you transmit to the Platform for the purpose of managing the
        performance of the Platform, as well as data relating to your use of the
        Platform. Although we perform regular routine backups of data, you are
        solely responsible for all data that you transmit or that relates to any
        activity you have undertaken using the Platform. You agree that we shall
        have no liability to you for any loss or corruption of any such data,
        and you hereby waive any right of action against us arising from any
        such loss or corruption of such data. Copyright Infringement We respect
        the intellectual property rights of others. If you believe that any
        material available on or through the Platform infringes upon any
        copyright you own or control, please immediately notify us using the
        contact information provided below (a “Notification”).
      </p>
      <p>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Platform infringes upon
        any copyright you own or control, please immediately notify us using the
        contact information provided below (a “Notification”).
      </p>
      <h2>Term And Termination</h2>
      <p>
        These Terms shall remain in full force and effect while you use the
        Platform. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE PLATFORM (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON, INCLUDING WITHOUT
        LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
        CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION.
        WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE PLATFORM OR DELETE
        YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
        WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <h2>Modifications And Interruptions</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Platform at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Platform. We also reserve the right to modify or discontinue all or part
        of the Platform without notice at any time. We will not be liable to you
        or any third party for any modification, price change, suspension, or
        discontinuance of the Platform.
      </p>
      <p>
        We cannot guarantee the Platform will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Platform, resulting in interruptions, delays,
        or errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Platform at any time or for any
        reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your
        inability to access or use the Platform during any downtime or
        discontinuance of the Platform. Nothing in these Terms will be construed
        to obligate us to maintain and support the Platform or to supply any
        corrections, updates, or releases in connection therewith.
      </p>
      <h2>Governing Law</h2>
      <p>
        Unless the laws of your jurisdiction require that those laws apply,
        these Terms and your use of the Platform are governed by and construed
        in accordance with the following, without regard to conflict of law
        principles: the laws of the United States of America and the State of
        Delaware, USA with venue in Kent County, Delaware.
      </p>
      <h2>Dispute Resolution</h2>
      <p>
        If you are a resident of the EEA, please see the EU Residents –
        Supplemental Terms of Use for more information.
      </p>
      <p>
        All other users, please read this section carefully. It affects your
        rights. You agree that by entering into these Terms, you and we are each
        waiving any right to trial by jury or to participate in a class action.
        You and we agree that each may bring claims against the other only in
        your or its individual capacity, and not as a plaintiff or class member
        in any purported class or representative proceeding. Any arbitration
        will take place on an individual basis; class arbitrations and class
        actions are not permitted.
      </p>
      <p>
        <strong>Governing Law.</strong> All claims, disputes, or other legal
        proceedings by or between you or us, including but not limited to any
        such claims or disputes that are in any way related to or arising under
        these Terms or your access to or use of the Platform, shall be governed
        by the laws of the United States of America and the State of Delaware
        without giving effect to any conflict of laws principles that may
        otherwise provide for the application of the law of another
        jurisdiction. Application of the United Nations Convention on Contracts
        for the International Sale of Goods and the Uniform Computer Information
        Transaction Act (UCITA) are expressly excluded by these Terms. The
        parties agree that their arrangement under these Terms is in interstate
        commerce and that the Federal Arbitration Act applies to the
        construction of the “Agreement to Binding Arbitration” provision below.
        For any claim, dispute, or other legal proceeding not subject to the
        “Agreement to Binding Arbitration” provision below, the claim or dispute
        shall be brought and litigated exclusively in the state courts located
        within Kent County, Delaware or the federal courts in the District of
        Delaware, as appropriate, and you agree to submit to the personal
        jurisdiction of each of these courts for the purpose of litigating such
        claims or disputes.
      </p>
      <p>
        <strong>Informal Negotiations.</strong> To expedite resolution and
        control the cost of any dispute, controversy, or claim related to these
        Terms (each a “Dispute” and collectively, the “Disputes”) brought by
        either you or us (individually, a “Party” and collectively, the
        “Parties”), the Parties agree to first attempt to negotiate any Dispute
        (except those Disputes expressly provided below) informally for at least
        thirty (30) days before initiating arbitration. Such informal
        negotiations commence upon written notice from one Party to the other
        Party. You may provide notice to us using the information provided in
        the “CONTACT US” section below.
      </p>
      <p>
        <strong>Agreement to Binding Arbitration.</strong> Subject to the
        Exceptions to Arbitration set forth below, you and we each agree that
        any and all disputes between Platform users arising under or related in
        any way to these Terms and such users’ use of the Platform must be
        resolved through binding arbitration as described in this section. With
        the exception of the prohibition on class arbitrations set forth in this
        “DISPUTE RESOLUTION” section, if an arbitrator or court decides that any
        part of this agreement to arbitrate is unenforceable, the other parts of
        this Agreement to Arbitrate will still apply.
      </p>
      <p>
        <strong>Exceptions to Arbitration.</strong> This Agreement to Arbitrate
        will not apply to the following: (a) small claims court cases; (b) legal
        proceedings that involve efforts to obtain user identifying information;
        (c) any legal proceedings brought against the Company by companies or
        other legal entities or individuals acting on behalf of such companies
        or other legal entities; (d) any legal proceedings brought by the
        Company against companies or other legal entities or individuals acting
        on behalf of any such companies or other legal entities; (e) a party’s
        right to seek injunctive or other equitable relief in a court of
        competent jurisdiction to prevent the actual or threatened infringement,
        misappropriation or violation of a party’s copyrights, trademarks, trade
        secrets, patents, or other intellectual property rights; or (f) where
        the application of this provision is prohibited by applicable law. If,
        for some reason, the prohibition on class arbitrations set forth in this
        DISPUTE RESOLUTION section cannot be enforced, then the entirety of this
        Agreement to Arbitrate will not apply. Where this Agreement to Arbitrate
        does not apply, the remainder of this Agreement and the Dispute
        Resolution section will continue to apply.
      </p>
      <p>
        <strong>Arbitration Procedure.</strong> The arbitration will be governed
        by the Consumer Arbitration Rules of the American Arbitration
        Association (“AAA”), as modified by this section, and will be
        administered by the AAA. The AAA’s rules and a form for initiating the
        proceeding are available at www.adr.org. Any settlement offer made by
        you or us shall not be disclosed to the arbitrator. Unless otherwise
        required by the applicable arbitration rules at the time the arbitration
        is commenced, the arbitration shall be held in Dover, Delaware. For any
        claim where the total amount of the award sought is $10,000 or less, you
        and we may elect to have the arbitration conducted by telephone or based
        solely on written submissions, which election shall be binding on you
        and us subject to the arbitrator’s discretion to require an in-person
        hearing. In cases where an in-person hearing is held, you or we may
        attend by telephone, unless the arbitrator requires otherwise. The
        arbitrator will decide the substance of all claims in accordance with
        applicable law, including recognized principles of equity, and will
        honor all claims of privilege recognized by law. The arbitrator shall
        not be bound by rulings in prior arbitrations involving different users,
        but is bound by rulings in prior arbitrations involving the same
        Platform user to the extent required by applicable law. The arbitrator’s
        award shall be final and binding and judgment on the award rendered by
        the arbitrator may be entered in any court having jurisdiction thereof.
        Limitation to Assert a Claim. In no event shall any Dispute be commenced
        more than one (1) year after the facts giving rise to the claim
        occurred.
      </p>
      <h2>Corrections</h2>
      <p>
        There may be information on the Platform that inadvertently contains
        typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other information. We
        reserve the right to correct any errors, inaccuracies, or omissions and
        to change or update the information on the Platform at any time, without
        prior notice.
      </p>
      <h2>Disclaimer</h2>
      <p>
        THE PLATFORM IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS, WHICH MEANS
        THAT WE GIVE NO GUARANTEES AS TO THE OPERATION OF THE PLATFORM. YOU
        AGREE THAT YOUR USE OF THE PLATFORM AND OUR SERVICES WILL BE AT YOUR
        SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND YOUR
        USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE PLATFORM’S CONTENT OR THE CONTENT OF ANY WEBSITES
        LINKED TO THE PLATFORM AND WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR
        ANY: (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS; (2)
        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
        FROM YOUR ACCESS TO AND USE OF THE PLATFORM; (3) ANY UNAUTHORIZED ACCESS
        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
        AND/OR FINANCIAL INFORMATION STORED ON THE SERVERS; (4) ANY INTERRUPTION
        OR CESSATION OF ANY TRANSMISSION TO OR FROM THE PLATFORM; OR (5) ANY
        VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
        THROUGH THE PLATFORM BY ANY THIRD PARTY. WE DO NOT WARRANT, ENDORSE,
        GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE RESPONSIBLE FOR
        MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
        PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
        THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>
      <h2>Limitation Of Liability</h2>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER WE NOR OUR DIRECTORS,
        OFFICERS, EMPLOYEES, OR AGENTS WILL BE LIABLE TO YOU OR ANY THIRD PARTY
        FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
        OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA,
        OR OTHER DAMAGES ARISING FROM YOUR USE OF THE PLATFORM, EVEN IF WE HAVE
        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
        ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
        CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
        TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE
        THREE (3) MONTH PERIOD PRIOR TO THE OCCURRENCE GIVING RISE TO ANY CLAIM.
        CERTAIN STATE OR NATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
        WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
        LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
        MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, employees,
        and agents from and against any loss, damage, liability, claim, or
        demand, including reasonable attorneys’ fees and expenses, made by any
        third party arising out of: (1) your Contributions or Submissions; (2)
        your use of the Platform; (3) your breach of these Terms; (4) any breach
        of your representations and warranties set forth in these Terms; (5)
        your violation of the rights of a third party, including but not limited
        to intellectual property rights; or (6) any harmful act by you toward
        any other user of the Platform with whom you connected via the Platform.
        Notwithstanding the foregoing unless prohibited by applicable law, we
        reserve the right, at your expense, to assume the exclusive defense and
        control of any matter for which you are required to indemnify us, and
        you agree to cooperate, at your expense, with our defense of such
        claims. We will use reasonable efforts to notify you of any such claim,
        action, or proceeding which is subject to this indemnification upon
        becoming aware of it.
      </p>
      <h2>Electronic Communications, Transactions, And Signatures</h2>
      <p>
        Visiting the Platform, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Platform, satisfy any legal requirement that such communication
        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE PLATFORM. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        These Terms and any policies or operating rules posted by us on the
        Platform or in respect to the Platform constitute the entire agreement
        and understanding between you and us. Our failure to exercise or enforce
        any right or provision of these Terms shall not operate as a waiver of
        such right or provision. These Terms operate to the fullest extent
        permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms is determined to be unlawful, void, or unenforceable,
        that provision or part of the provision is deemed severable from these
        Terms and does not affect the validity and enforceability of any
        remaining provisions. There is no joint venture, partnership, employment
        or agency relationship created between you and us as a result of these
        Terms or use of the Platform. Except where prohibited by applicable law,
        you agree that these Terms will not be construed against us by virtue of
        having drafted them. You hereby waive any and all defenses you may have
        based on the electronic form of these Terms and the lack of signing by
        the parties hereto to execute these Terms.
      </p>
      <h2>Contact Us</h2>
      <p>
        Contact Us In order to resolve a complaint regarding the Platform or to
        receive further information regarding use of the Platform, please
        contact us at info@kylanthomson.dev.
      </p>
      <h2>EU RESIDENTS – SUPPLEMENTAL TERMS OF USE</h2>
      <p>Last Updated: 7/1/2024</p>
      <p>
        We offer our Platform and services within the European Union. These
        supplemental terms of use apply to you if you are a resident of the
        European Union (“EU Supplemental Terms”). Therefore, if you are residing
        in the European Union, the general Terms of Use and these EU
        Supplemental Terms will apply. If there is any conflict between the
        Terms of Use and these EU Supplemental Terms, and these EU Supplemental
        Terms apply to you, then the EU Supplemental Terms will prevail.
      </p>
      <p>
        Unless otherwise specified, definitions in this EU Supplemental Terms
        will have the same meaning as the definitions used in the Terms of Use.
        Prior to the creation of your account, the text of these Terms of Use
        will be made available to you electronically via the Platform in such a
        manner that it can be easily stored by you on a durable data carrier.
      </p>
      <p>
        We reserve the right, in our sole discretion, to make changes or
        modifications to the Terms of Use or these EU Supplemental Terms at any
        time. We will inform you about any changes before the effective date of
        the changes. If you continue to use the Platform after the effective
        date of the changes, you will be deemed to have been made aware of and
        to have accepted the changes in any revised Terms or EU Supplemental
        Terms.
      </p>
      <p>** Intellectual Property Rights</p>
      <p>
        Intellectual Property Rights are all present and future Intellectual
        Property Rights, anywhere in the world, in respect of the Platform,
        belonging to us and/or our licensors now or in the future, including
        copyrights, database rights, trademark rights, design rights, trade name
        rights, domain name rights, patent rights, trade secret rights as well
        as similar rights under unwritten law, such as rights regarding slavish
        imitation. Provided that you are eligible to use the Platform, you are
        granted, solely for the purpose of execution of your account, a limited,
        non-exclusive, non-transferable and non-sublicensable license to access
        and use the Platform and to download or print a copy of any portion of
        the Content to which you have properly gained access solely for your
        personal, non-commercial use. We reserve all rights not expressly
        granted to you in and to the Platform, the Content and the Marks. This
        license is given for the duration of your account and you hereby accept
        this license. We are at all times entitled to terminate this license
        unilaterally, early and with immediate effect, without having to pay you
        any compensation
      </p>
      <p>
        You indemnify us against any loss arising from any infringement of
        Intellectual Property Rights by you in respect of the Platform. You will
        compensate us for all costs and loss, including the costs of legal
        assistance, arising from a violation of Intellectual Property Rights.
        You will take measures at your own expense in order to limit the costs
        to be incurred and/or loss to be suffered by us.
      </p>
      <p>** Fees And Payment</p>
      <p>
        If we determine any taxes, such as sales taxes, or any other charges
        apply to our fees, the price of purchase on the Platform will include
        these taxes and/or fees during the ordering process. If your purchase is
        subject to recurring charges, then you have the option to provide us the
        right to charge you payment by direct debit. In which case we will
        charge your payment on a recurring basis, without requiring you prior
        approval before each recurring charge, until you notify us of your
        cancellation.
      </p>
      <p>** Right Of Withdrawal</p>
      <p>
        When purchasing digital content, you, as a consumer residing in the EU,
        have the right to withdraw from the agreement for a period of 14 days
        from the delivery date, without providing a reason for the withdrawal.
        The right to withdraw from the agreement for the supply of digital
        content shall not apply if you have expressly stated that you wish to
        receive the digital content within the withdrawal period and have
        expressly waived the right of withdrawal.
      </p>
      <p>
        When entering into a 7-day trial subscription, a money-back-guarantee
        shall apply for the duration of the trial subscription. Within 14 days
        of your declaration that you are invoking this guarantee, the amount
        already paid by you shall refunded. Liability
      </p>
      <p>
        We are not liable for: a) any misunderstandings, errors or failures with
        respect to the performance of the Platform and/or our services, if these
        are caused by or are the result of your actions, such as the failure to
        supply complete, sound, correct and clear data (or the failure to do so
        in time); b) errors or failures by third parties engaged by or on behalf
        of you; c) information that third parties have placed on the Platform.
        We can only be held liable for direct damages or loss attributable to
        us. Liability for any (financial) damage or loss other than direct
        damages and/or loss, such as indirect damage or loss, including but not
        limited to consequential damage or loss, loss of profit, damage or loss
        caused by stolen, inaccessible, mutilated, destroyed or lost (personal)
        data or materials, lost savings, loss due to business interruption or
        damage or loss due to or resulting from a security leak, security breach
        or cybercrime, is excluded. Except in the case of gross negligence or
        wilful misconduct, our aggregate liability for damage or loss arising
        from our services and/or the Platform, or otherwise arising out of or in
        connection with these Terms, will be limited to an amount equal to the
        fees paid to us by you.
      </p>
      <p>** Force Majeure</p>
      <p>
        In the event of force majeure, we will not be obliged to fulfil any of
        our obligations, including any statutory and/or agreed guarantee
        obligation. Furthermore, we are not liable for any damage suffered by
        you as a result of a force majeure situation. Force majeure on our part
        includes, but is not limited to: a) suppliers on whom we depend not
        fulfilling their obligations; b) defective goods, equipment, software or
        materials from third parties; c) epidemics and pandemics; d) when our
        network comes to a standstill for reasons for which we cannot be held
        accountable, including - but not limited to - a cyber-attack or (D)DoS
        attacks in any form or a failure in internet traffic, hardware or
        software problems or any other external cause; e) maintenance to the
        Platform, resulting in interruptions, delays or errors; f) government
        measures including financial regulation and legislation; g) strikes,
        forced business closures, riots and any other form of disruption and/or
        hindrance caused by third parties, which hinders us; h) illness of one
        or more employees who are difficult to replace; i) a power failure,
        failure of internet, data network or telecommunications facilities; or
        j) any other cause beyond our reasonable control.
      </p>
      <p>** Dispute Resolution</p>
      <p>
        All claims, disputes, or other legal proceedings by or between you or
        us, including but not limited to any such claims or disputes that are in
        any way related to or arising under the Terms of Use, the EU
        Supplemental Terms, or your access to or use of the Platform, shall be
        governed by the laws of the Netherlands.
      </p>
      <p>
        Application of the United Nations Convention on Contracts for the
        International Sale of Goods is expressly excluded by the Terms of Use
        and these EU Supplemental Terms.
      </p>
      <p>
        Unless you opt – in writing – for settlement of the dispute by the court
        which is competent under the law within one month after this provision
        was invoked in writing, each claim, dispute, or other legal proceeding
        shall be brought and litigated exclusively to the court of the
        Netherlands, located in Amsterdam.
      </p>
      <p>** Complaints</p>
      <p>
        In order to resolve a complaint regarding the Platform, please contact
        us within a reasonable period of time after you have detected or could
        reasonably have detected a problem at info@kylanthomson.dev. We will
        reply within a reasonable period of time.
      </p>
      <h2>US RESIDENTS – SUPPLEMENTAL TERMS OF USE</h2>
      <p>Last Updated: 7/1/2024</p>
      <p>
        These supplemental terms of use to you if you are a resident of the
        United States (“US Supplemental Terms”). Therefore, if you reside in the
        United States, both Terms of Use and this US Supplemental Terms will
        apply. If there is any conflict between the Terms of Use and these US
        Supplemental Terms and these US Supplemental Terms apply to you, then
        the US Supplemental Terms will prevail.
      </p>
      <p>** Copyright Infringement</p>
      <p>
        If you believe that any material available on or through the Platform
        infringes upon any copyright you own or control, please immediately
        notify us using the contact information provided below (a
        “Notification”). A copy of your Notification will be sent to the person
        who posted or stored the material addressed in the Notification. In
        accordance with the Online Copyright Infringement Liability Limitation
        Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (DMCA),
        the Notification must include substantially the following:
      </p>
      <ul>
        <li>Your physical or electronic signature;</li>
        <li>
          identification of the copyrighted work you believe to have been
          infringed;
        </li>
        <li>
          identification of the material on the Platform that you believe is
          infringing the copyright in a sufficiently precise manner to allow us
          to locate the material;
        </li>
        <li>
          your contact information including your name, mailing address,
          telephone number and, if available, email address;
        </li>
        <li>
          a statement that you have a good faith belief that use of the
          copyrighted material is not authorized by the copyright owner, its
          agent or the law;
        </li>
        <li>a statement that the information in the notice is accurate; and</li>
        <li>
          a statement, under penalty of perjury, that you are the copyright
          owner or are authorized to act on behalf of the copyright owner.
        </li>
      </ul>
      <p>
        If you do not comply with these requirements, your notice may not be
        effective. If you knowingly misrepresent that materials on the Platform
        infringe a copyright, you may be held liable for damages, including
        costs and attorney’s fees under Section 512(f) of the DMCA. Thus, if you
        are not sure that material located on or linked to by the Platform
        infringes your copyright, you should consider first contacting an
        attorney. U.S. Government Rights
      </p>
      <p>
        Our services are “commercial items” as defined in Federal Acquisition
        Regulation (“FAR”), 48 C.F.R. § 2.101. If our services are acquired by
        or on behalf of any agency not within the Department of Defense (“DOD”),
        our services are subject to the terms of these Terms in accordance with
        48 C.F.R. § 12.212 (for computer software) and 48 C.F.R. § 12.211 (for
        technical data). If our services are acquired by or on behalf of any
        agency within the Department of Defense (“DOD”), our services are
        subject to these Terms in accordance with Defense Federal Acquisition
        Regulation (“DFARS”), 48 C.F.R. § 227.7202-3. In addition, 48 C.F.R. §
        252.227-7015 applies to technical data acquired by the DOD. This U.S.
        Government Rights clause is in lieu of, and supersedes, any other FAR,
        DFARS, or other clause or provision that addresses government rights in
        computer software or technical data under these Terms.
      </p>
      <p>Last Updated: 7/1/2024</p>
    </LongContent>
  );
}

export default LegalTerms;
