// In this file, a functional component called AuthPage is defined.
// The component renders a Meta component, which is used to set the title of the page, and an AuthSection component.
// The AuthSection component is passed some props, including bgColor, size, bgImage, bgImageOpacity, type, providers,
// and afterAuthPath.

// The AuthSection component is likely a form that allows a user to authenticate themselves using one of
// the provided authentication providers (e.g. Google, Facebook, Twitter).
// The type prop is used to determine whether the form is for signing in or signing up.
// The afterAuthPath prop specifies the path that the user should be redirected to after successfully authenticating.

import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google"]}
        // TODO: revert
        // providers={["google", "facebook", "twitter"]}
        // afterAuthPath={router.query.next || "/dashboard"}
        afterAuthPath={router.query.next || "/pro"}
      />
    </>
  );
}

export default AuthPage;
