// This contact.js file exports a single function called submit.
// This function is used to send data to a third-party service called Formspree,
// which can be used to handle form submissions.

// The submit function uses the apiRequestExternal function, which is imported from the util.js file,
// to send a POST request to the Formspree API with the specified data.
// The endpoint for the API is constructed by concatenating the base Formspree API URL with a unique identifier
// that is stored in an environment variable called REACT_APP_FORMSPREE_CONTACT_ID.

// When this function is called, it sends a POST request to the Formspree API with the specified data,
// and returns a promise that resolves with the response from the API.

// The contact object is then exported and contains the submit function.
// This object can be imported and used in other parts of the application to send form submissions to Formspree.

import { apiRequestExternal } from "./util.js";

const endpoint = `https://formspree.io/f/${process.env.REACT_APP_FORMSPREE_CONTACT_ID}`;

function submit(data) {
  return apiRequestExternal(endpoint, "POST", data);
}

const contact = { submit };

export default contact;
