import React, { useRef, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import HeroVideo from "./../images/resumes_revived.mp4";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  figure: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  },
  image: {
    margin: "0 auto",
    maxWidth: 800,
    display: "block",
    height: "auto",
    width: "100%",
    borderRadius: "50px",
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "12px 24px",
    fontSize: "1.1rem",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "30px",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: theme.palette.primary.dark,
      transform: "translateY(-2px)",
      boxShadow:
        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
    },
    "&:active": {
      transform: "translateY(1px)",
    },
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
    fontSize: "1.3rem",
    transition: "transform 0.3s ease",
  },
  buttonWrapper: {
    display: "inline-block",
    "&:hover .MuiSvgIcon-root": {
      transform: "translateX(4px)",
    },
  },
  flameWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    pointerEvents: "none",
  },
}));

function HeroSection(props) {
  const auth = useAuth();
  const classes = useStyles();
  const videoRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const sectionRef = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (sectionRef.current) {
        const { width, height } = sectionRef.current.getBoundingClientRect();
        setDimensions({ width, height });
        console.log("HeroSection dimensions:", width, height);
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      ref={sectionRef}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
              />
              <div className={classes.buttonWrapper}>
                <Button
                  component={Link}
                  to={
                    auth.user && auth.user.planId === "pro"
                      ? `/${auth.user.planId}`
                      : props.buttonPath
                  }
                  variant="contained"
                  size="large"
                  className={classes.button}
                  endIcon={<ArrowForwardIcon className={classes.buttonIcon} />}
                >
                  {props.buttonText}
                </Button>
              </div>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <figure className={classes.figure}>
              <video
                ref={videoRef}
                className={classes.image}
                autoPlay
                loop
                muted
                playsInline
                src={HeroVideo}
              ></video>
            </figure>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection;
