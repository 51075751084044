import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

function PricingSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const plans = [
    // {
    //   id: "starter",
    //   name: "Resume Revival Starter Plan",
    //   price: "4.99",
    //   perks: [
    //     "Access to AI-generated resumes and cover letters tailored to your experience and job description.",
    //     "Access to an ever-growing library of templates, so you can pick a style that fits you best",
    //     "Downloadable, Windows and Mac-friendly .docx files for easy editing and submission",
    //     "Unlimited revisions, allowing you to fine-tune your resume and cover letter until they're just right.",
    //     "Exclusive access to new features and improvements as they become available.",
    //     "Save time and effort by letting our AI do the heavy lifting, while you focus on what's most important: landing your dream job!",
    //   ],
    // },
    {
      id: "pro",
      name: "Resume Revival Pro Plan",
      price: "5.95",
      perks: [
        "Access to AI-generated resumes and cover letters tailored to your experience and job description.",
        "Access to an ever-growing library of templates, so you can pick a style that fits you best",
        "Downloadable, Windows and Mac-friendly .docx files for easy editing and submission",
        "Unlimited revisions, allowing you to fine-tune your resume and cover letter until they're just right.",
        "Exclusive access to new features and improvements as they become available.",
        "Save time and effort by letting our AI do the heavy lifting, while you focus on what's most important: landing your dream job!",
      ],
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {plans.map((plan, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ textAlign: "center" }}
                  >
                    {plan.name}
                  </Typography>
                  <Box
                    className={classes.price}
                    mt={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h3">${plan.price}</Typography>
                    <Typography variant="h4" color="textSecondary">
                      /mo
                    </Typography>
                  </Box>

                  {plan.description && (
                    <Box mt={2}>
                      <Typography component="p" color="textSecondary">
                        {plan.description}
                      </Typography>
                    </Box>
                  )}

                  {plan.perks && (
                    <Box mt={1}>
                      <List aria-label="perks">
                        {plan.perks.map((perk, index) => (
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                            key={index}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{perk}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}

                  <Box mt="auto" pt={3}>
                    <Button
                      component={Link}
                      to={
                        auth.user
                          ? `/purchase/${plan.id}`
                          : (() => {
                              return `/auth/signup?next=/purchase/${plan.id}`;
                            })()
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                    >
                      Choose
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingSection;
