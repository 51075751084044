// The ContactPage component is a page that displays a Contact form for users to fill out and submit. 
// The form is created using the Contact component, which takes various props such as 
// the background color and image, size, title and subtitle, button text and color, and whether to show the name field. 
// When the form is submitted, it sends the data to an endpoint, which is specified in the contact.js file in the util folder. 
// The ContactPage component also includes a Meta component, which sets the page title to "Contact" and adds a 
// description to the page.

import React from "react";
import Meta from "./../components/Meta";
import Contact from "./../components/Contact";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <Contact
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default ContactPage;
