// This pricing.js file is a page in a React application that displays a pricing section to the user.
// It does this by importing the PricingSection component and rendering it in the page.
// The PricingSection component will likely display a table or list of pricing plans, along with details about each plan
// such as the features included and the cost.

// The page also sets the title of the page to "Pricing" using the Meta component,
// which is likely responsible for rendering metadata (such as the title and description) for the page.

// When the page is loaded, the PricingSection component will be rendered with the specified background color,
// size, image, and other props such as the title and subtitle. The user can then browse the available pricing plans
// and choose the one that is most suitable for their needs.

import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        // subtitle="Choose the plan that makes sense for you. All plans include a 14-day free trial."
        subtitle="More plans coming soon. All plans include a 14-day free trial."
      />
    </>
  );
}

export default PricingPage;
