import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Q: What is Resume Revival?",
      answer:
        "A: Resume Revival is a state-of-the-art, free-to-use web application that uses advanced AI to help job seekers create customized, professional resumes and cover letters. It also provides a skill gap analysis to help users identify areas of improvement to better fit their target jobs.",
    },
    {
      question: "Q: How does Resume Revival work?",
      answer:
        "A: Resume Revival crafts unique resumes and cover letters by understanding your professional history and aligning it with your target job description. The result is a keyword-rich, ATS-friendly document that resonates with recruiters.",
    },
    {
      question: "Q: What is a skill gap analysis?",
      answer:
        "A: A skill gap analysis is a feature provided by Resume Revival to help users identify areas where they might need further development to become a perfect fit for their dream job.",
    },
    {
      question: "Q: Does Resume Revival charge for its services?",
      answer:
        "A: No, Resume Revival is a free-to-use application. Our mission is to make the job application process more efficient and effective for job seekers everywhere.",
    },
    {
      question: "Q: Can I choose different styles or templates for my resume?",
      answer:
        "A: Yes, Resume Revival offers multiple stylized templates to choose from, ensuring your resume not only performs well but also looks appealing.",
    },
    {
      question: "Q: Is my data safe with Resume Revival?",
      answer:
        "A: Resume Revival is committed to user privacy and data security. Your professional data is only used for the purpose of generating your resume and cover letter and is not shared with any third parties.",
    },
    {
      question: "Q: What does 'ATS-friendly' mean?",
      answer:
        "A: 'ATS-friendly' means that the document is designed to pass through Applicant Tracking System (ATS) software often used by recruiters to screen resumes. Resume Revival ensures your resume is rich with relevant keywords and is formatted correctly to achieve this.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
