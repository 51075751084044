// In this file, a SettingsPage component is being defined as a function that returns a JSX element. 
// The component is using the Meta component and the SettingsSection component to render a page with 
// meta data and a settings section, respectively. The SettingsPage component is also using the useRouter hook 
// to get access to the router object in order to pass the section query parameter to the SettingsSection component 
// as a prop. Finally, the SettingsPage component is being wrapped with the requireAuth higher-order component, 
// which means that the user will have to be authenticated in order to access this page.

import React from "react";
import Meta from "./../components/Meta";
import SettingsSection from "./../components/SettingsSection";
import { useRouter } from "./../util/router";
import { requireAuth } from "./../util/auth";

function SettingsPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Settings" />
      <SettingsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default requireAuth(SettingsPage);
