// The BasicPage component is a functional component that is used to render the content of a page on a website.
// It uses a number of other components, such as Meta, JobDetails, ChooseTemplate, and StatsSection2, to render
// the different sections of the page. The Meta component is used to set the title and description of the page,
// which can be used by search engines and other web browsers to understand the content of the page.
// The JobDetails component is used to display job details, such as the job title and company name,
// and has a button that allows users to send a message. The ChooseTemplate component is used to allow users to select a
// template for their resumes or cover letters. Finally, the StatsSection2 component is used to display statistics or
// other information. All of these components are rendered within the ProPage component, which is then exported and
// can be imported and used in other parts of the application.
import React from "react";
import Meta from "../components/Meta";
import JobDetails from "../components/JobDetails";
import { useAuth, requireAuth } from "../util/auth";

function ProPage(props) {
  return (
    <>
      <Meta title="Pro" />
      <JobDetails
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="All the features you need to move faster"
        subtitle="Boost My Job Applications"
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}
export default requireAuth(ProPage);
// export default BasicPage;
