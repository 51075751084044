import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={6}>
            <DashboardItems />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Box>
                  {/* <Typography variant="h6" paragraph={true}>
                    <strong>Get to Know Your Dashboard</strong>
                  </Typography> */}

                  {/* <Typography paragraph={true}>
                    Welcome to the Resume Revival user dashboard! Here, you'll
                    find all the essential information and tools you need to
                    manage your account, making it easier than ever to tailor
                    your job-seeking experience with our innovative platform.
                  </Typography>
                  <Typography paragraph={true}>
                    On this page, you can access your current subscription plan
                    details, effortlessly upgrade or downgrade to suit your
                    needs, and even cancel your subscription if you wish. Stay
                    in control of your Resume Revival experience and ensure it
                    aligns with your job search objectives.
                  </Typography>
                  <Typography paragraph={true}>
                    Additionally, this section allows you to update your
                    personal settings, such as changing your password for
                    enhanced security, or updating your billing information to
                    ensure a seamless and uninterrupted service. Customize your
                    account to fit your preferences, and let Resume Revival help
                    you land your dream job.
                  </Typography> */}
                  <Typography variant="h6" paragraph={true}>
                    <strong>Welcome to the Early Adopters Club!</strong>
                  </Typography>
                  <Typography paragraph={true}>
                    Hello there, brave pioneer! You've landed on the nascent and
                    twinkling shores of your Resume Revival dashboard - an area
                    that's currently being meticulously crafted by our
                    code-wielding wizards to be as useful and intuitive as
                    possible.
                  </Typography>
                  <Typography paragraph={true}>
                    Right now, we're donning our digital hard hats and working
                    tirelessly, pounding out algorithms and sanding down rough
                    code edges to bring you an incredible platform that will
                    amplify your job-hunting journey. Here’s a glimpse of what’s
                    in the pipeline: effortless plan management, personal
                    setting customization, the ability to update your billing
                    information at the click of a button, and so much more!
                  </Typography>
                  <Typography paragraph={true}>
                    So, while our 'coming soon' sign is still hanging, we're
                    thrilled to have you here amidst the sawdust of creation. We
                    promise that the wait will be worth it. Soon, you'll be able
                    to harness the full power of Resume Revival to secure your
                    dream job.
                  </Typography>
                  <Typography paragraph={true}>
                    Stay tuned and watch this space, because like the finest
                    wine or a good sourdough starter, the best things take time.
                    Thanks for your patience, and remember, Rome wasn't built in
                    a day, but they were laying bricks every hour!
                  </Typography>
                  <Typography paragraph={true}>Happy navigating!</Typography>
                  <Box mt={3}>
                    <Typography variant="h6" paragraph={true}>
                      <strong>Dashboard Essentials</strong>
                    </Typography>
                    <Typography component="div">
                      <div>
                        You are signed in as <strong>{auth.user.email}</strong>.
                      </div>

                      {auth.user.stripeSubscriptionId && (
                        <>
                          {/* <div>
                            You are subscribed to the{" "}
                            <strong>{auth.user.planId} plan</strong>.
                          </div>
                          <div>
                            Your plan status is{" "}
                            <strong>
                              {auth.user.stripeSubscriptionStatus}
                            </strong>
                            .
                          </div> */}
                        </>
                      )}

                      <div>
                        You can change your account info{` `}
                        {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                        in{` `}
                        <LinkMui component={Link} to="/settings/general">
                          <strong>settings</strong>
                        </LinkMui>
                        .
                      </div>

                      {!auth.user.stripeSubscriptionId && (
                        <div>
                          You can signup for a plan in{" "}
                          <LinkMui component={Link} to="/pricing">
                            <strong>pricing</strong>
                          </LinkMui>
                          .
                        </div>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSection;
