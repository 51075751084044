import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacy Policy</h1>
      <p>Last Updated: 2/5/2022</p>
      <p>
        Thank you for choosing to be part of our community. At
        resumerevival.xyz, we are committed to protecting your personal data and
        respecting your privacy rights. This Privacy Policy (“Policy”) explains
        what personal data is collected and how it is used by Resume Revival
        Inc. when you access or use the resumerevival.xyz site. This Policy and
        other documents referenced in it apply to all personal data collected
        through our platform and any related services, sales, marketing or
        events.
      </p>
      <p>
        Personal data is any information relating to an identified or
        identifiable natural person. It includes things like email addresses,
        phone numbers, mailing addresses, payment card information, account
        numbers, and government-issued identification numbers. These are just
        examples and there are many other types of information that would be
        considered personal data.
      </p>
      <p>
        Because we operate globally, we apply local rules regarding the use and
        sale of personal data, as well as the choice to receive communications
        from us and our affiliate companies. This Policy generally describes our
        collection and use practices, but please review the specific notice
        below for your geographic area, which provides more specific information
        regarding our practices and your rights:
      </p>
      <p>
        We hope you take some time to read through this Policy carefully,
        because it is important. If there are any terms in this Policy that you
        do not agree with, please immediately discontinue use of the platform
        and our services. If you have any questions or concerns about our
        collection or use of your information, please contact us at
        info@kylanthomson.dev.
      </p>
      <h2>A. What personal data do we collect?</h2>
      <h3>1. Personal data you disclose to us</h3>
      <p>
        We collect personal data that you voluntarily provide to us when
        registering and interacting with the platform, expressing an interest in
        obtaining information about us or our products and services, when
        participating in activities on the platform or otherwise contacting us.
      </p>
      <p>
        The personal data that we collect depends on the context of your
        interactions with us and the platform, the choices you make and the
        products and features you use. The personal data we collect may include
        the following:
      </p>
      <p>
        Name and Contact Data. We collect your first and last name, email
        address, mailing address, phone number, and other similar contact data.
      </p>
      <p>
        Credentials. We collect usernames, passwords, password hints, and
        similar security information used for authentication and account access.
      </p>
      <p>
        Employment, Resume, CV and other Job Related Data. We collect the
        employment, resume, CV and other job related data that you submit to us
        or provide us access to in connection with the platform.
      </p>
      <p>
        Payment Data. We collect data necessary to process your payment if you
        make purchases, such as your payment instrument number (such as a credit
        card number), and the security code associated with your payment
        instrument. All payment data is stored by our third-party payment
        processor and you should review its privacy policies and contact the
        payment processor directly to respond to your questions.
      </p>
      <p>
        Social Media Login Data. We provide you with the option to register
        using social media account details, like your Facebook, Twitter or other
        social media account. If you choose to register in this way, we will
        collect the Information described in the section “How do we handle your
        social logins”
      </p>
      <p>
        All personal data that you provide to us must be true, complete and
        accurate, and you must notify us of any changes to such personal data.
      </p>
      <h3>2.Data automatically collected</h3>
      <p>
        We automatically collect certain information when you visit, use or
        navigate the platform. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        the platform and other technical information. This information is
        primarily used to maintain the security and operation of the platform,
        and for our internal analytics and reporting purposes.
      </p>
      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies. This information may include what URL you visited
        before visiting our platform, how long you spend on a particular page,
        and how you navigate our platform. Please refer to our Cookie Policy for
        more information on how we use cookies.
      </p>
      <h3>3.Data collected from other sources</h3>
      <p>
        We and our affiliates generally share personal data with each other so
        that we can provide our services to you. We also may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, social media platforms (such as Facebook), as well as from
        other third parties.
      </p>
      <p>
        Examples of the information we receive from other sources include:
        social media profile information (your name, gender, birthday, email,
        current city, state and country, user identification numbers for your{" "}
      </p>
      <p>
        contacts, profile picture URL and any other information that you choose
        to make public); marketing leads and search results and links, including
        paid listings (such as sponsored links). When you choose to, for
        example, obtain feedback on a resume through one of our third-party
        marketing partner sites, your contact information and resume will be
        sent to us.
      </p>
      <h2>B. Do we collect data from minors?</h2>
      <p>
        Our platform is not directed to minors and we do not intend to collect
        personal data from children under the age of 18. Therefore, we recommend
        that parents or legal guardians be involved in the online activities of
        their children in order to prevent the collection of their personal data
        without parental consent. If you believe that we inadvertently have
        collected personal data about your child, please contact us at
        info@kylanthomson.dev and we will promptly delete or anonymise this
        information.
      </p>
      <h2>C. How do we use your personal data?</h2>
      <p>
        We use personal data collected through our platform for a variety of
        business purposes described below. We process your personal data for
        these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We use the information
        we collect or receive:
      </p>
      <p>
        To facilitate account creation and logon process. We use information you
        provide to create an account with us. If you choose to link your account
        with us to a third party account (such as your Google or Facebook
        account), we use the information you allowed us to collect from those
        third parties to facilitate account creation and logon process. See the
        section below “How do we handle your social logins ” for further
        information.
      </p>
      <p>
        To send administrative information to you. We may use your personal data
        to send you information about products or services you have purchased
        and/or information about changes to our terms, conditions, and policies.
      </p>
      <p>
        To fulfill and manage your orders for services. We may use your
        information to fulfill and manage your orders for services,
        subscriptions, and payments, and to address any issues with services
        provided through the platform.
      </p>
      <p>
        To post testimonials. We post testimonials on the platform that may
        contain personal data. Prior to posting a testimonial, we will obtain
        your consent to use your name and testimonial. If you wish to update, or
        delete your testimonial, please contact us at info@kylanthomson.dev and
        be sure to include your name, testimonial location, and contact
        information.
      </p>
      <p>
        To send you marketing and promotional communications. We and/or our
        third-party marketing partners may use your personal data for our
        marketing purposes, depending on your marketing preferences. These
        communications may include information about new products, services, or
        features. You can opt-out of our marketing emails at any time (see the
        section “what are your privacy rights” below).
      </p>
      <p>
        To deliver targeted advertising to you. We may use your information to
        develop and display content and advertising (and work with third parties
        who do so) tailored to your interests and/or location and to measure its
        effectiveness.
      </p>
      <p>
        To request feedback. We may use your information to request feedback and
        to contact you about your use of our platform so that we can improve our
        products and services and develop additional products and services to
        better serve our users.
      </p>
      <p>To develop our products and services.</p>
      <p>
        We may use your information to further develop our existing products and
        services or to develop new products and services. To protect the
        platform, us, and others. We may use your information as part of our
        efforts to keep the platform safe and secure (for example, for fraud
        monitoring and prevention) and to protect others from harm or abuse.
      </p>
      <p>To enforce our terms, conditions and policies.</p>
      <p>
        We may use your information to ensure you and others are complying with
        our terms, conditions and policies, including providing your information
        to law enforcement if we believe it is appropriate to do so and using
        your information in connection with legal proceedings brought by us or
        against us.
      </p>
      <p>
        To respond to legal requests and comply with applicable law. If we
        receive a subpoena, discovery request, or other legal request, we may
        need to review and evaluate your information and potentially provide
        your information in response to the request. We may also need to use
        your information as needed to comply with applicable laws and
        regulations.
      </p>
      <p>
        For other business purposes. We may use your information for other
        business purposes, such as data analysis, identifying usage trends,
        determining the effectiveness of our promotional campaigns, and to
        evaluate and improve the platform, products, services, and your
        experience.
      </p>
      <h2>D. Will your personal data be shared?</h2>
      <p>
        Yes, your personal will be shared between Resume Revival, Inc. and its
        affiliates and subsidiaries. In addition, we may share your personal
        data with the following categories of third-party service providers and
        marketing partners for one or more of the purposes described above.
        *Advertising, direct marketing, and lead generation providers *Chat
        service providers *Content optimization service providers *Invoice and
        billing service providers *Social media companies to which you enable
        access *Social media site advertising *Software service providers that
        parse and match resumes with job postings *Targeted advertising
        platforms *Website and application analytics providers *Website hosting
        providers *Website testing and optimization service providers In
        addition, where we believe it necessary or appropriate to do so, we may
        share your information with: law enforcement; government agencies or
        regulators; courts; parties to legal proceedings in which a subpoena or
        legal request has been propounded to which such information would be
        responsive. In those circumstances we will make reasonable efforts to
        disclose only that information that is relevant to the reason for
        disclosure and to protect the privacy of such information to the extent
        reasonably possible. Finally, we may share or transfer your information
        in connection with, or during negotiations of, any merger, sale of
        company assets, financing, or acquisition of all or a portion of our
        business to another company.
      </p>
      <h2>E. Do we use cookies and other tracking technologies?</h2>
      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to collect or store information. Specific information about
        how we use such technologies and how you can refuse or disable certain
        cookies is set out in our Cookie Policy.
      </p>
      <h2>F. How do we handle your social logins?</h2>
      <p>
        The platform may offer you the ability to register and login using your
        third party social media account details (like your Facebook or Twitter
        logins). If you choose to do this, we will receive certain profile
        information about you from the social media company. The profile
        information we receive may vary depending on the social media provider,
        but will often include your name, email address, friends list, profile
        picture as well as other information you choose to make public.
      </p>
      <p>
        We will use the information we receive for the purposes that are
        described in this Policy. For more information about your rights with
        respect to this information, please see the section “What are your
        privacy rights?” below.
      </p>
      <p>
        Please note that we do not control, and are not responsible for, other
        uses of your personal data by the social media company. We recommend
        that you review their privacy policy to understand how they collect, use
        and share your personal data, and how you can set your privacy
        preferences on their sites and applications.
      </p>
      <h2>G. How long do we keep your information?</h2>
      <p>
        We will only keep your personal data for as long as it is necessary for
        the purposes set out in this Policy, unless a longer retention period is
        required or permitted by law (such as tax, accounting or other legal
        requirements).
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal data, we will either delete or anonymize it.
      </p>
      <h2>H. How do we keep information safe?</h2>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal data we
        collect, receive, use, or store. However, please also remember that we
        cannot guarantee that the internet itself is 100% secure. Although we
        will do our best to protect your personal data, transmission of personal
        data to and from the platform is at your own risk. You should only
        access the platform within a secure environment. If you believe your
        account has been compromised or you suspect any type of data breach,
        please contact us at info@kylanthomson.dev.
      </p>
      <h2>I. What are your privacy rights?</h2>
      <p>
        The rights described in this section apply to all users. As noted above,
        if you reside in a particular state or country, you may have additional
        rights as required by applicable law. Please see our specific notices
        for those regions for more information about your specific rights:
      </p>
      <p>Account Information</p>
      <p>
        If you would at any time like to review or change the information in
        your account to make sure it is accurate and up-to-date, please log into
        your user account and update the information as needed. If you terminate
        your subscription or account with us, then we will deactivate your
        account. However, we retain a copy of all resumes created by us or on
        the Platform, and some additional information may be retained in our
        files to prevent fraud, troubleshoot problems, assist with any
        investigations, enforce our Terms of Use, or comply with applicable
        legal requirements. We try to minimize the data we retain to only that
        which is necessary for these purposes.
      </p>
      <p>Opt Out of Email Marketing</p>
      <p>
        You can unsubscribe from our marketing email list at any time by
        clicking on the unsubscribe link in the emails that we send. Even if you
        opt out of marketing emails, we may still send you service-related
        emails that are necessary for the administration and use of your
        account.
      </p>
      <h2>J. Do we make updates to this policy?</h2>
      <p>
        We may update this Policy from time to time. The updated version will be
        indicated by the “Last Updated” date and the updated version will be
        effective as soon as it is accessible. We encourage you to review the
        posted Policy frequently to stay informed about how your information is
        being used.
      </p>
      <h2>K. How can you contact us about this policy?</h2>
      <p>
        If you have questions or comments about this Policy, you may contact our
        Data Protection Officer (DPO) by email at info@kylanthomson.dev.
      </p>
      <h2>European economic area residents notice</h2>
      <p>
        We offer our platform and services within the European Union and
        therefore the General Data Protection Regulation (“GDPR”) applies. This
        notice applies to you if you are resident in the European Economic Area
        (“EEA”: the countries of the European Union as well as Norway,
        Liechtenstein and Iceland) (“GDPR notice”).
      </p>
      <p>
        Therefore, if you are located in the EEA, the general considerations in
        the Policy and the considerations specifically set out in this GDPR
        notice will apply. If there is a conflict between the Policy and the
        GDPR notice and this GDPR notice applies to you, then the GDPR notice
        will prevail.
      </p>
      <p>
        Unless otherwise specified, definitions in this EU Supplemental Terms
        will have the same meaning as the definitions used in the Terms of Use.
        If you have any questions or concerns about our collection or use of
        your information, please contact us at info@kylanthomson.dev.
      </p>
      <h3>Processors And International Data Transfers</h3>
      <p>
        Insofar as third parties process your personal data on our behalf, they
        do so as processors as defined in the GDPR. In these cases, we are the
        data controller, as we determine the purposes and means of data
        processing. We will only engage processors who adequately implement
        appropriate technical and organizational measures and who guarantee the
        protection of your rights. In order to ensure this, we enter into data
        processing agreements with our processors. This is compulsory under the
        GDPR. The data processing agreement regulates, among other things, data
        security, confidentiality and your rights.
      </p>
      <p>
        If your personal data are transferred to ‘third countries’ – i.e.,
        countries outside the EEA – we will only do so if the third country
        offers an adequate level of protection. In some countries outside the
        EEA – please, check here – your personal data are sufficiently protected
        in accordance with European data protection laws and regulations. If
        this is not the case, we provide appropriate safeguards to ensure
        adequate data protection. For example, by using the most recent standard
        contractual clauses as approved by the European Commission and by
        implementing additional safeguards. If no appropriate safeguards are in
        place, we will only transfer your personal data if a legal exception
        applies. An example of a legal exception is explicit consent.
      </p>
      <h3>When And How Do We Request Consent?</h3>
      <h3>1. Collecting and sharing your personal data</h3>
      <p>
        As set out in the Policy, we may collect your personal data from other
        sources and we may share your personal data with affiliated companies,
        group companies and subsidiaries, third-party services providers and
        marketing partners. In these cases and to the extent that the receiving
        party is not a processor on our behalf, we will only collect or share
        your personal data if we have obtained your consent. This consent is
        obtained for example via a consent banner or a cookie banner depending
        on the receiving party.
      </p>
      <h3>2. Marketing purposes</h3>
      <p>
        We will not use your electronic contact details for marketing purposes,
        unless you are our customer or meet another exception under the
        applicable telecommunications laws. As mentioned in the Policy, you can
        unsubscribe from our marketing email list at any time by clicking on the
        unsubscribe link in the emails that we send (this is also called ‘OPT
        OUT’). If you are not our existing customer, we will ask for your
        consent to send direct marketing messages (this is also called ‘OPT
        IN’).
      </p>
      <p>
        For the underlying processing of your personal data for marketing
        purposes, we may process your personal data on the basis of our
        legitimate interest, except when your interests or your fundamental
        rights and freedoms outweigh our interests. This legal basis is referred
        to below as ‘legitimate interest’.
      </p>
      <h3>3. Special categories of personal data</h3>
      <p>
        Our platform does not intend to collect special categories of personal
        data, such as personal data revealing racial or ethnic origin, political
        opinions, religious or philosophical beliefs. However, we cannot fully
        guarantee that we will not collect such data if you provide it to us
        (for example, in your resume). We will not use it for purposes other
        than creating your resume.
      </p>
      <h3>Additional Privacy Rights</h3>
      <p>
        If you are a resident of the EEA, you may invoke a number of privacy
        rights additional to those mentioned in the Policy, which are the right
        to review or change the information in your account to make sure it is
        accurate and up-to-date. The additional rights include:
      </p>
      <h3>Right to Erasure.</h3>
      <p>
        You generally have a right to erasure; however, we may not always erase
        your personal data because sometimes the processing is still necessary:
        for example, for complying with statutory obligations or for lodging,
        exercising or substantiating legal claims. Therefore, we will weigh your
        interest in having your personal data erased against the necessity of
        maintaining the personal data for other legitimate interests. Regarding
        the deactivation of your account we refer to the information mentioned
        in the Policy.
      </p>
      <h3>Right to Restriction of Processing.</h3>
      <p>
        Under certain circumstances, you may have us restrict the processing of
        your personal data. For example, if the accuracy of your personal data
        are contested.
      </p>
      <h3>Notification of Rectification, Deletion or Restriction.</h3>
      <p>
        Unless it is impossible or involves a disproportionate effort, we will
        inform the recipients of your personal data about rectification,
        deletion or restriction of processing.
      </p>
      <h3>Right to Data Portability. </h3>
      <p>
        Under certain circumstances, you may request us to transfer your
        personal data to another party.
      </p>
      <h3>Right of Objection.</h3>
      <p>
        Furthermore, under certain circumstances you may object to the
        processing of your personal data by us (or third parties engaged by us).
        For example, you may object to the use of your personal data for
        marketing purposes.
      </p>
      <h3>Right to Withdraw Consent. </h3>
      <p>
        {" "}
        If we have requested your consent for data processing, you have the
        right to withdraw your consent at any time. The withdrawal of consent
        will not affect the lawfulness of the processing prior to the
        withdrawal.
      </p>
      <h3>Right of Complaint.</h3>
      <p>
        If you believe that the processing of your personal data violates
        applicable laws and regulations, please contact us at
        info@kylanthomson.dev. We will always try to find a solution together.
        Should this not be possible, you can lodge a complaint with the Dutch
        Data Protection Authority, or any other competent authority in the
        European Union, or to take legal action.
      </p>
      <h3>How to exercise these rights and what we may need from you?</h3>
      <p>
        You can submit your request(s) for exercising these rights to us at
        info@kylanthomson.dev, please provide your name, telephone number, and
        type of request. To protect your privacy and security, we will take
        reasonable steps to verify your identity before responding to your
        request(s). Only you or someone legally authorized to act on your behalf
        may make a verifiable request related to your personal data. For
        example, if you make a request, we will ask you to confirm your name,
        email address, and/or other information we in our records to verify your
        identity. This is a security measure to ensure that personal data are
        not disclosed to someone who has no right to receive it.
      </p>
      <p>
        We will respond to your request as soon as possible, and no later than
        one month from receipt of your request. In doing so, we will also assess
        whether, and if so to what extent, we can or must comply with your
        request under the law. If a longer period of up to two months is needed,
        we will inform you within one month of receipt of the request. The
        submission and processing of your request is free of charge, unless your
        request is manifestly unfounded or excessive, in particular because of a
        repetitive character.
      </p>
      <h3>Representative In The EEA</h3>
      <p>
        If you have questions or comments about this GDPR notice, you may
        contact our Data Protection Officer (DPO) by email at
        info@kylanthomson.dev.
      </p>
      <h2>U.S. Residents Notice</h2>
      <p>
        This Notice provides more information about specific disclosures and
        rights we provide to residents of the U.S. in accordance with U.S. law.
      </p>
      <h3>Categories of Information Collected</h3>
      <p>
        In the 12 months preceding the Last Updated date of this Policy, we have
        collected or received the following categories of personal information
        (the “Category” information corresponds to the categories in the
        California Consumer Privacy Act).
      </p>

      <table>
        <tr>
          <th>Category</th>
          <th>Types of information collected</th>
          <th>Purpose of collection</th>
        </tr>
        <tr>
          <td>A. Identifiers</td>
          <td>
            Name, mailing address, Internet Protocol (IP) address, email
            address.
          </td>
          <td>
            Account registration, fulfilling customer orders for our services,
            marketing our services, customer service
          </td>
        </tr>
        <tr>
          <td>
            B. Personal information categories listed in the California Customer
            Records statute (Cal. Civ. Code § 1798.80(e)).
          </td>
          <td>
            Name, address, telephone number, education, employment, employment
            history, credit card number, debit card number. (Some personal
            information included in this category may overlap with other
            categories.)
          </td>
          <td>
            Account registration, Fulfilling customer orders for our services,
            Marketing our services, Customer service
          </td>
        </tr>
        <tr>
          <td>
            C. Characteristics of protected classifications under California or
            federal law.
          </td>
          <td>
            Age (40 years or older), race, color, national origin, citizenship,
            physical or mental disability, sex (including gender, gender
            identity, gender expression, pregnancy or childbirth and related
            medical conditions), veteran or military status.
          </td>
          <td>
            Fulfilling customer orders for our services, Analytics and
            improvement of our services, Customer service
          </td>
        </tr>
        <tr>
          <td>D. Commercial information.</td>
          <td>Records of products or services purchased.</td>
          <td>
            Account registration, Fulfilling customer orders for our services,
            Marketing our services, Customer service
          </td>
        </tr>
        <tr>
          <td>E. Internet or other similar network activity.</td>
          <td>
            Browsing history, search history, information on a consumer’s
            interaction with a website, application, or advertisement.
          </td>
          <td>Web site optimization, marketing our services</td>
        </tr>
        <tr>
          <td>F. Geolocation data</td>
          <td>Physical location or movements.</td>
          <td>Web site personalization, Marketing our services</td>
        </tr>
        <tr>
          <td>G. Sensory data.</td>
          <td>Audio, visual, and electronic information..</td>
          <td>Fulfilling customer orders for our services</td>
        </tr>
        <tr>
          <td>H. Professional or employment-related information.</td>
          <td>Current or past job history or performance evaluations.</td>
          <td>
            Fulfilling customer orders for our services, marketing our services,
            Customer service, Analytics and improvement of our services
          </td>
        </tr>
        <tr>
          <td>I. Education information that is not publicly available</td>
          <td>
            Education records such as education history and degrees achieved..
          </td>
          <td>
            Fulfilling customer orders for our services, marketing our services,
            Customer service, Analytics and improvement of our services
          </td>
        </tr>
        <tr>
          <td>J. Inferences drawn from other personal information.</td>
          <td>
            Profile about a consumer reflecting the consumer’s preferences,
            characteristics, psychological trends, predispositions, behavior,
            attitudes, intelligence, abilities, and aptitudes.
          </td>
          <td>
            Marketing our services, Analytics and improvement of our services
          </td>
        </tr>
      </table>

      <h3>Categories of Information Disclosed</h3>
      <p>Categories of Information Disclosed</p>
      <ul>
        <li>Category A: Identifiers</li>
        <li>
          Category B: California Customer Records personal information
          categories
        </li>
        <li>Category C: Protected characteristics information</li>
        <li>Category D: Commercial information</li>
        <li>Category F: Internet or other similar network activity</li>
        <li>Category G: Geolocation data</li>
        <li>Category H: Sensory data</li>
        <li>Category I: Professional or employment-related information</li>
        <li>Category J: Education information</li>
        <li>Category K: Inferences drawn from other personal information</li>
      </ul>
      <h3>Categories of Information “Sold”</h3>
      <p>
        We do not sell your personal data for a monetary fee; however, some U.S.
        privacy laws define a “sale” very broadly such that it may include our
        sharing of your personal data with certain third parties if we receive
        anything of value in return. In that regard, we share your personal data
        with certain third-party marketing partners and in exchange we receive
        the ability to market or offer our products and services through their
        websites and applications. This may be considered a “sale” under some
        laws. The personal data that may be shared or sold in this context is:
        your name; email address; other contact information; and information
        obtained through cookies about, for example, your location, the website
        that directed you to our Platform, how you navigate our sites, and what
        purchases you make.
      </p>
      <p>
        Other than these third-party marketing partners, there are no other
        third parties to which we will sell your personal data.
      </p>
      <h3>Do-Not-Track Features</h3>
      <p>
        Many web browsers and some mobile operating systems and mobile
        applications include a “do-not-track” feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. No uniform
        technology standard for recognizing and implementing do-not-track
        signals has been finalized and, as such, we do not currently respond to
        do not track browser signals or any other mechanism that automatically
        communicates your choice not to be tracked online. We will update this
        Policy if that changes in the future.
      </p>
      <h3>Additional Privacy Rights</h3>
      <h3>Right to Information.</h3>
      <p>
        Subject to certain limits, you may ask us to provide the following
        information for the 12-month period preceding your request: (1) The
        categories of personal information we collected about you; (2) The
        categories of sources from which the personal information was collected;
        (3) The business or commercial purpose for collecting the personal
        information; (4) The categories of third parties with whom we shared the
        personal information; (5) If we disclosed personal information for a
        business purpose, a list of the disclosures including the personal
        information categories that each category of recipient received; and (6)
        The specific pieces of personal information we collected about you. We
        do not provide these information rights for personal information that we
        obtain through a business-to-business (B2B) relationship.
      </p>
      <h3>Right to Delete</h3>
      <p>
        You also have the right to ask us to delete any personal information
        that we have collected about you, subject to certain limitations. We may
        deny your deletion request if the information is necessary for us or our
        service providers to, among other things, provide a service you
        requested, take actions reasonably anticipated in the context of our
        business relationship with you, perform a contract we have with you,
        detect and protect against security incidents or illegal activity,
        comply with a legal obligation, or exercise a right provided for by law.
      </p>
      <h3>Right to Opt Out of Sale.</h3>
      <p>
        You have the right to opt out of the sale of your personal information.
        You may exercise this right by emailing info@kylanthomson.dev.
      </p>
      <p>**Right to Nondiscrimination. **</p>
      <p>
        We will not discriminate against you if you exercise your privacy rights
        under any applicable privacy law, including by:
      </p>
      <ul>
        <li>Denying you goods or services.</li>
        <li>
          Charging you different prices or rates for goods or services,
          including through granting discounts or other benefits, or imposing
          penalties.
        </li>
        <li>
          Providing you a different level or quality of goods or services.
        </li>
        <li>
          Suggesting that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
      <p>
        We are permitted under applicable law to offer you certain financial
        incentives that can result in different prices, rates, or quality
        levels, which are related to your personal information’s value.
        Participation in a financial incentive program requires your prior
        opt-in consent, which you may revoke at any time.
      </p>
      <h3>Submission of Requests.</h3>
      <p>You may submit a request regarding your personal information by:</p>
      <p>
        Emailing us at info@kylanthomson.dev – please provide your name,
        telephone number, and type of request (that is, a request for categories
        of information, a request for specific pieces of information, a request
        to delete, or a request to opt out the sale of your information).
      </p>
      <h3>What We May Need from You.</h3>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal information
        (or to exercise any of your other rights). This is a security measure to
        ensure that personal information is not disclosed to someone who has no
        right to receive it. We may also contact you to ask you for further
        information in relation to your request to speed up our response.
      </p>
      <p>
        Verifying Your Identity: To protect your privacy and security, we will
        take reasonable steps to verify your identity before providing your
        personal information and before deleting your information. Only you or
        someone legally authorized to act on your behalf may make a verifiable
        request related to your personal information. For example, if you make a
        request, we will ask you to confirm your name, email address, and/or
        other information we in our records to verify your identity, so that we
        can help protect your information.
      </p>
      <p>
        Requests from Authorized Agents: You may designate an authorized agent
        to make a request for you. If you designate an authorized agent to make
        a request on your behalf, we may require you to verify your identity and
        provide the authorized agent’s identity and contact information to us.
      </p>
      <h3>Responses to Requests.</h3>
      <p>
        We will confirm receipt of your request within 10 business days of
        receipt and generally will respond to your request within 45 days of
        receipt. If we need more time to respond, we will inform you of the
        reason and we may take up to an additional 45 days to respond.
      </p>
      <h3>No Fee Usually Required.</h3>
      <p>
        You will not have to pay a fee to access your personal information (or
        to exercise any of the other rights). However, we may charge a
        reasonable fee if your request is clearly unfounded, repetitive or
        excessive. Alternatively, we may refuse to comply with your request in
        these circumstances.
      </p>
      <p>© 2024 Resume Revival, Inc.</p>
      <p></p>
      <p></p>
      <p>This policy was last updated on July 1th, 2024.</p>
    </LongContent>
  );
}

export default LegalPrivacy;
