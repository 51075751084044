/*

This file defines the PurchasePage component, which is used to handle user purchases and payments.

Define the PurchasePage functional component.
Declare a state variable formAlert with its associated setter setFormAlert.
useRouter and useAuth hooks are called to access router and auth objects.
useEffect hook is called with auth.user as a dependency.

If the user has an active plan, they are redirected to the settings/billing page.

If the user doesn't have an active plan, they are redirected to the checkout page.


If redirectToCheckout encounters an error, it updates formAlert with an error message.

Render the PurchasePage component.
Use the Meta component to set the page title to "Purchase".
Use the PageLoader component to display a loading indicator.
sql

If formAlert exists, render an Alert component with appropriate severity and style, displaying the formAlert message.
Wrap the PurchasePage component with requireAuth to ensure user authentication before accessing the page.
Export the wrapped PurchasePage component as the default export.
*/

import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import { useAuth, requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { redirectToCheckout } from "./../util/stripe";

function PurchasePage(props) {
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState();

  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user already has an active plan
      // then take them to Stripe billing
      router.push("/settings/billing");
    } else {
      // Otherwise go to checkout
      redirectToCheckout(router.query.plan).catch((error) => {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <>
      <Meta title="Purchase" />
      <PageLoader>
        {formAlert && (
          <Alert severity={formAlert.type} style={{ maxWidth: "500px" }}>
            {formAlert.message}
          </Alert>
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PurchasePage);
