import React from "react";
import {
  useTheme,
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createLocalStorageStateHook } from "use-local-storage-state";

const themeConfig = {
  light: {
    palette: {
      type: "light",
      primary: {
        main: colors.indigo["500"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: "#fff",
        paper: "#fff",
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.87)",
          },
          "&$focused $notchedOutline": {
            borderColor: colors.indigo["500"],
          },
        },
        input: {
          backgroundColor: "white",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px white inset",
            WebkitTextFillColor: "#000",
          },
        },
      },
      MuiInputBase: {
        input: {
          backgroundColor: "white",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px white inset",
            WebkitTextFillColor: "#000",
          },
        },
      },
    },
  },
  dark: {
    palette: {
      type: "dark",
      primary: {
        main: colors.indigo["500"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: colors.grey["900"],
        paper: colors.grey["800"],
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: "rgba(255, 255, 255, 0.23)",
          },
          "&:hover $notchedOutline": {
            borderColor: "rgba(255, 255, 255, 0.87)",
          },
          "&$focused $notchedOutline": {
            borderColor: colors.indigo["300"],
          },
        },
        input: {
          backgroundColor: "transparent",
          color: "#fff",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px rgba(0, 0, 0, 0.5) inset",
            WebkitTextFillColor: "#fff",
            caretColor: "#fff",
          },
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
        input: {
          backgroundColor: "transparent",
          color: "#fff",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px rgba(0, 0, 0, 0.5) inset",
            WebkitTextFillColor: "#fff",
            caretColor: "#fff",
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
          "&$focused": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
        input: {
          backgroundColor: "transparent",
        },
      },
      MuiTextField: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
  },
  common: {
    typography: {
      fontSize: 14,
      fontFamily: '"Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "#root": {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
      MuiPickersDay: {
        day: {
          color: "inherit",
        },
        daySelected: {
          backgroundColor: colors.indigo["500"],
          "&:hover": {
            backgroundColor: colors.indigo["700"],
          },
        },
        dayDisabled: {
          color: colors.grey["500"],
        },
        current: {
          color: colors.indigo["500"],
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: colors.indigo["500"],
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: colors.indigo["500"],
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: "white",
        },
        toolbarBtnSelected: {
          color: "white",
        },
      },
    },
  },
};

function getTheme(name) {
  return createTheme({
    ...themeConfig[name],
    ...themeConfig.common,
    overrides: {
      ...(themeConfig.common && themeConfig.common.overrides),
      ...(themeConfig[name] && themeConfig[name].overrides),
    },
  });
}

const useDarkModeStorage = createLocalStorageStateHook("isDarkMode");

export const ThemeProvider = (props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  let [isDarkModeStored, setIsDarkModeStored] = useDarkModeStorage();

  const isDarkMode =
    isDarkModeStored === undefined ? prefersDarkMode : isDarkModeStored;

  const themeName = isDarkMode ? "dark" : "light";
  const theme = getTheme(themeName);

  theme.palette.toggle = () => setIsDarkModeStored((value) => !value);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={isDarkMode ? "dark-mode" : ""}>{props.children}</div>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export function useDarkMode() {
  const theme = useTheme();
  const isDarkMode = theme.palette.type === "dark";
  return { value: isDarkMode, toggle: theme.palette.toggle };
}
