// The firebase.js file is a utility file that initializes a Firebase app instance 
// and exports it as a constant named firebaseApp. If the file detects that a Firebase 
// app instance has already been initialized, it will use the existing instance. 
// Otherwise, it will create a new instance by calling the initializeApp() function from 
// the Firebase library and passing in the required API key, auth domain, 
// and project ID as configuration. These values are stored in environment variables and accessed 
// through the process.env object. The firebaseApp constant is then made available for other parts of the application to use.

import { initializeApp, getApps, getApp } from "firebase/app";

let app;
if (getApps().length === 0) {
  // Initialize Firebase app
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  });
} else {
  // Use existing app if already initialized
  app = getApp();
}

export const firebaseApp = app;
