// This premium.js file is a React component that is responsible for rendering the content of the "Premium" page of the project.
// The page appears to consist of three sections:

// A Contact section, which appears to display a form for contacting the company.
// A ChooseTemplate section, which appears to allow the user to select a template for their resume or cover letter.
// A StatsSection section, which appears to display some statistics or other information to the user.
// The component is wrapped in a call to the requireAuth function, which means that
// the user must be authenticated (logged in) to access this page.

// The Meta component at the top is responsible for rendering the page's title element in the document head,
// which is used to populate the title of the webpage.

import React from "react";
import Meta from "./../components/Meta";
import Contact from "./../components/Contact";
import StatsSection from "./../components/StatsSection";
import { requireAuth } from "./../util/auth";

function PremiumPage(props) {
  return (
    <>
      <Meta title="Premium" />
      <Contact
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Job Details"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />

      <StatsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default requireAuth(PremiumPage);
