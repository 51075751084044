import React from "react";
import CookieConsent from "react-cookie-consent";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import ProPage from "./pro";
import PlusPage from "./plus";
import PremiumPage from "./premium";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import logo_image from "./../images/logo.png";

function App(props) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <>
              <Navbar
                color="default"
                logo={logo_image}
                logoInverted={logo_image}
              />

              <Switch>
                <Route exact path="/" component={IndexPage} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/faq" component={FaqPage} />
                <Route exact path="/contact" component={ContactPage} />
                <Route exact path="/pricing" component={PricingPage} />
                <Route exact path="/dashboard" component={DashboardPage} />
                <Route exact path="/auth/:type" component={AuthPage} />
                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />
                <Route exact path="/legal/:section" component={LegalPage} />
                <Route exact path="/pro" component={ProPage} />
                <Route exact path="/plus" component={PlusPage} />
                <Route exact path="/premium" component={PremiumPage} />
                <Route exact path="/purchase/:plan" component={PurchasePage} />
                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />
                <Route component={NotFoundPage} />
              </Switch>

              <Footer
                bgColor="light"
                size="normal"
                bgImage=""
                bgImageOpacity={1}
                description="A short description of what you do here"
                copyright={`© ${new Date().getFullYear()} Resume Revival`}
                logo={logo_image}
                logoInverted={logo_image}
                sticky={true}
              />

              <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="myAppCookieConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
              >
                We use cookies to personalize content and ads, provide social
                share features, and analyze our traffic. To deliver personalized
                ads, we share information about your use of our site with our
                advertising and analytics partners. View our{" "}
                <a href="/legal/privacy-policy" style={{ color: "#ffffff" }}>
                  Cookie Policy
                </a>{" "}
                to learn how to manage your cookie settings. Read our{" "}
                <a href="/legal/privacy-policy" style={{ color: "#ffffff" }}>
                  Privacy Policy
                </a>
                .
              </CookieConsent>
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
