import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Snackbar,
  Button,
  Typography,
  Divider,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  SectionHeader,
  SubsectionHeader,
  FormField,
  DateHelperText,
} from "./FormComponents";
import SchoolIcon from "@material-ui/icons/School";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formContent: {
    position: "relative",
  },
  formInnerContent: {
    padding: theme.spacing(3),
  },
  educationEntries: {
    marginTop: theme.spacing(4),
  },
  educationLevelButtons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    maxWidth: "600px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  educationLevelButton: {
    flex: "1 1 auto",
    padding: theme.spacing(1, 2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    transition: "all 0.3s",
    minWidth: "100px",
    maxWidth: "150px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      fontSize: "0.875rem",
      minWidth: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%", // Add this line

      maxWidth: "200px", // Add this line
    },
  },
  educationLevelTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  entryContainer: {
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  subsection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  currentlyEnrolledCheckbox: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

const initialEducation = {
  educationType: "College",
  institutionName: "",
  city: "",
  stateProvince: "",
  country: "",
  degree: "",
  fieldOfStudy: "",
  minor: "",
  startDate: "",
  endDate: "",
  currentlyEnrolled: false,
  gpa: "",
  additionalInfo: "",
};

const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "Other",
];
const degrees = ["Associate", "Bachelor", "Master", "Doctorate"];

const EducationEntry = React.memo(
  ({
    index,
    education,
    handleChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
  }) => {
    const classes = useStyles();

    const onFieldChange = useCallback(
      (name, value) => {
        handleChange(index, name, value);
      },
      [handleChange, index]
    );

    const onRemoveEntry = useCallback(() => {
      handleRemoveEntry(index);
    }, [handleRemoveEntry, index]);

    const isCurrentlyEnrolled = useMemo(
      () => education.currentlyEnrolled,
      [education.currentlyEnrolled]
    );

    const formFields = useMemo(() => {
      const commonFields = [
        {
          section: "Institution Details",
          fields: [
            {
              name: "institutionName",
              label: "Institution Name",
              required: true,
              fullWidth: true,
              icon: SchoolIcon,
            },
            { name: "city", label: "City" },
            { name: "stateProvince", label: "State/Province" },
            {
              name: "country",
              label: "Country",
              required: true,
              autocomplete: true,
              options: countries,
            },
          ],
        },
        {
          section: "Education Dates",
          fields: [
            {
              name: "startDate",
              label: "",
              type: "date",
              required: true,
              helperText: "Enter the date you started your education",
            },
            {
              name: "endDate",
              label: "",
              type: "date",
              required: !isCurrentlyEnrolled,
              hidden: isCurrentlyEnrolled,
              helperText:
                "Enter the date you completed or expect to complete your education",
            },
          ],
        },
      ];

      const collegeFields = [
        {
          section: "Degree Information",
          fields: [
            {
              name: "degree",
              label: "Degree",
              required: true,
              select: true,
              options: degrees,
            },
            {
              name: "fieldOfStudy",
              label: "Field of Study",
              required: true,
            },
            { name: "minor", label: "Minor" },
            {
              name: "gpa",
              label: "GPA",
              type: "number",
              inputProps: { min: 0, max: 4, step: 0.01 },
            },
          ],
        },
      ];

      const additionalFields = [
        {
          section: "Additional Information",
          fields: [
            {
              name: "additionalInfo",
              label:
                "Additional Information (Honors, Awards, Activities, etc.)",
              multiline: true,
              rows: 4,
              fullWidth: true,
            },
          ],
        },
      ];

      switch (education.educationType) {
        case "High School":
          return [...commonFields, ...additionalFields];
        case "College":
          return [...commonFields, ...collegeFields, ...additionalFields];
        default:
          return commonFields;
      }
    }, [education.educationType, isCurrentlyEnrolled]);

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader
          title={`${education.educationType} Education ${index + 1}`}
        />
        {formFields.map(({ section, fields }) => (
          <React.Fragment key={section}>
            <SubsectionHeader title={section} className={classes.subsection} />
            <Grid container spacing={3} className={classes.formSection}>
              {fields.map(
                (field) =>
                  !field.hidden && (
                    <Grid
                      item
                      xs={12}
                      sm={field.fullWidth ? 12 : 6}
                      key={field.name}
                    >
                      <FormField
                        name={field.name}
                        label={field.label}
                        required={field.required}
                        value={education[field.name] || ""}
                        onChange={(name, value) =>
                          handleChange(index, name, value)
                        }
                        error={errors[field.name]}
                        tooltip={`Enter the ${field.label.toLowerCase()}`}
                        icon={field.icon ? <field.icon /> : null}
                        fullWidth
                        multiline={field.multiline}
                        rows={field.rows}
                        type={field.type}
                        select={field.select}
                        options={field.options}
                        inputProps={field.inputProps}
                      >
                        {field.select &&
                          field.options.map((option) => (
                            <MenuItem
                              key={option.value || option}
                              value={option.value || option}
                            >
                              {option.label || option}
                            </MenuItem>
                          ))}
                      </FormField>
                      {field.type === "date" && (
                        <DateHelperText text={field.helperText} />
                      )}
                    </Grid>
                  )
              )}
            </Grid>
            {section === "Education Dates" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCurrentlyEnrolled}
                    onChange={(e) =>
                      onFieldChange("currentlyEnrolled", e.target.checked)
                    }
                    name="currentlyEnrolled"
                    color="primary"
                  />
                }
                label="I am currently enrolled"
                className={classes.currentlyEnrolledCheckbox}
              />
            )}
          </React.Fragment>
        ))}
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this education entry">
            <IconButton
              onClick={onRemoveEntry}
              color="secondary"
              aria-label="Remove education entry"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another education entry">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add education entry"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

export const Education = React.memo(() => {
  const classes = useStyles();
  const {
    formData,
    errors,
    updateFormData,
    setEducation,
    validateFieldAndUpdateErrors,
  } = useContext(FormContext);

  useEffect(() => {
    const storedData = localStorage.getItem("education");
    if (
      storedData &&
      (!formData.education || formData.education.length === 0)
    ) {
      setEducation(JSON.parse(storedData));
    }
  }, []);

  const handleChange = useCallback(
    (index, name, value) => {
      updateFormData("education", (prev) => {
        const updated = Array.isArray(prev) ? [...prev] : [];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
      validateFieldAndUpdateErrors(`education[${index}]`, name, value);
    },
    [updateFormData, validateFieldAndUpdateErrors]
  );

  const handleAddEntry = useCallback(() => {
    updateFormData("education", (prev) => {
      const lastEntry = prev[prev.length - 1];
      const newEntry = {
        ...initialEducation,
        educationType: lastEntry.educationType,
      };
      return [...prev, newEntry];
    });
  }, [updateFormData]);

  const handleRemoveEntry = useCallback(
    (index) => {
      updateFormData("education", (prev) => {
        const updated = prev.filter((_, i) => i !== index);
        return updated.length > 0
          ? updated
          : [{ ...initialEducation, educationType: "NA" }];
      });
    },
    [updateFormData]
  );

  const handleEducationLevelChange = useCallback(
    (level) => {
      updateFormData("education", (prev) => {
        if (level === "NA") {
          return [{ ...initialEducation, educationType: "NA" }];
        }
        return [{ ...initialEducation, educationType: level }];
      });
    },
    [updateFormData]
  );

  useEffect(() => {
    localStorage.setItem("education", JSON.stringify(formData.education));
  }, [formData.education]);

  const isNA = useMemo(
    () => formData.education[0]?.educationType === "NA",
    [formData.education]
  );

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <div className={classes.formContent}>
        <SectionHeader title="Education History" />
        <div className={classes.formInnerContent}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className={classes.educationLevelTitle}
                align="center"
              >
                Highest Level of Education:
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.educationLevelButtons}>
            {["College", "High School", "NA"].map((level) => (
              <Button
                key={level}
                className={`${classes.educationLevelButton} ${
                  formData.education[0]?.educationType === level ? "active" : ""
                }`}
                onClick={() => handleEducationLevelChange(level)}
              >
                {window.innerWidth <= 600 && level === "High School"
                  ? "High School"
                  : level}
              </Button>
            ))}
          </div>
          <Divider />
          {!isNA && (
            <div className={classes.educationEntries}>
              {formData.education.map((education, index) => (
                <EducationEntry
                  key={index}
                  index={index}
                  education={education}
                  handleChange={handleChange}
                  handleRemoveEntry={handleRemoveEntry}
                  handleAddEntry={handleAddEntry}
                  errors={errors[`education[${index}]`] || {}}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={!!errors.submit}
        autoHideDuration={6000}
        onClose={() => updateFormData("errors", { submit: null })}
      >
        <Alert
          onClose={() => updateFormData("errors", { submit: null })}
          severity="error"
        >
          {errors.submit}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default Education;
