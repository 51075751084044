// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs

// The prices.js file defines a mapping between custom plan IDs and Stripe price IDs,
// and provides utility functions for working with these IDs.
// The custom plan IDs are strings like "basic", "premium", etc.,
// and the Stripe price IDs are unique identifiers used by Stripe to identify subscription plans.
// The file exports two functions: getStripePriceId and getFriendlyPlanId.

// The getStripePriceId function takes a custom plan ID as an argument and returns the
// corresponding Stripe price ID from the stripePriceIds object.
// For example, if you pass in "starter", the function will return the value of the
// REACT_APP_STRIPE_PRICE_STARTER environment variable, which is expected to contain a Stripe price ID.

// The getFriendlyPlanId function takes a Stripe price ID as an argument and returns the
// corresponding custom plan ID. This function searches the stripePriceIds object for a key that has a value
// matching the given Stripe price ID, and returns the key if a match is found.
// This function is useful for converting a Stripe price ID to a more user-friendly plan ID.

const stripePriceIds = {
  starter: process.env.REACT_APP_STRIPE_PRICE_STARTER,
  pro: process.env.REACT_APP_STRIPE_PRICE_PRO,
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}
