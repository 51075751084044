// The 404.js file defines a React component that represents the content of a 404 error page. 
// It is intended to be displayed when the user navigates to a route that does not match any of 
// the defined routes in the application.

// The component could display a message such as "Sorry, the page you are looking for could not be found" 
// and may include a link or button for the user to navigate back to the home page or other relevant page in the application. 
// The specific content and layout of the 404 error page is up to the developer to define.


import React from "react";
import Meta from "./../components/Meta";

function NotFoundPage(props) {
  return (
    <>
      <Meta title="404" />
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        The page <code>{props.location.pathname}</code> could not be found
      </div>
    </>
  );
}

export default NotFoundPage;
